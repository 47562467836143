import {emit, on} from './events';
import {addLoadingIndicator, boolval, removeLoadingIndicator} from './helper';

on('lazy.content', async function (data) {
  let target: HTMLElement = data.element;

  if (data.target) {
    target = document.querySelector(data.target);
  }

  addLoadingIndicator(target);

  try {
      const response = await fetch(target.dataset.lazyContentUrl, {
          headers: {
              'X-Requested-With': 'XMLHttpRequest',
          },
      });
      target.innerHTML = await response.text();
  } catch (error) {
      console.error(error);
  }

  removeLoadingIndicator(target);
})

on('element.remove', async function (params) {
    console.log('element.remove', params);
    const target: HTMLElement = params.element;
    const closest = params.closest;

    if (!closest) {
        console.error("failed to remove element as closest param is missing")
        return;
    }

    const element = target.closest(closest);

    if (!element) {
        console.error("failed to find element to remove", target, closest)
        return;
    }

    element.remove();
});

on('toggle.display', function (params) {
    console.log('toggle.display', params);
    let targets = params.targets;

    if (!targets) {
        const target = params.target;

        if (!target) {
            return;
        }

        targets = [target];
    } else {
        targets = targets.split(',')
    }

    targets.forEach((selector) => {
        const target = document.querySelector(selector);

        if (!target) {
            return;
        }

        let defaultDisplay = window.getComputedStyle(target).getPropertyValue('display');

        if (defaultDisplay == 'none') {
            defaultDisplay = 'inherit';
        }

        target.style.display = (target.style.display == 'none') ? defaultDisplay : 'none';
    });
});

// test event for now
on('toggle.class', function(params) {
    console.log('toggle.class', params);
    if (!params.target || !params.class) {
        return;
    }

    const target = document.querySelector(params.target);

    if (!target) {
        return;
    }

    target.classList.toggle(params.class);
});

on('quantity.input.init', function(data) {
    const minus = data.element.querySelector(".quantity-minus")
    const plus = data.element.querySelector(".quantity-plus");
    const quantity = data.element.querySelector(".quantity");
    const emitEventName = data.emit;

    quantity.addEventListener('keyup', function (e) {
        if (emitEventName) {
            emit(emitEventName, {
                quantity: e.target.value,
            });
        }
    });

    minus.addEventListener('click', function(){
        if (quantity.min != undefined && parseFloat(quantity.min) > parseFloat(quantity.value) - 1) {
            return;
        }

        quantity.value--;

        if (emitEventName) {
            emit(emitEventName, {
                quantity: quantity,
            });
        }
    });

    plus.addEventListener('click', function() {
        if (quantity.max != undefined && parseFloat(quantity.max) < parseFloat(quantity.value) + 1) {
            return;
        }

        quantity.value++;

        if (emitEventName) {
            emit(emitEventName, {
                quantity: quantity,
            });
        }
    });

    // TODO add support for long press -> keep adding/removing number untill pressed

    console.log('quantity done', data);
});

on('page.popup', function(data) {
    data.event.preventDefault();

    let target = data.event.target;

    if (!target.hasAttributes('href')) {
        target = target.closest('[href]');
    }

    Wolmart.popup({
        type: 'ajax',
        items: {
            src: target.getAttribute('href')
        },
    }, data.preset);
});

on('page.quickview', function(data) {
    Wolmart.popup({
        type: 'ajax',
        items: {
            src: data.event.target.getAttribute('href')
        },
        callbacks: {
            open: function () {
                // this.wrap.imagesLoaded(function () {
                //Wolmart.productSingle($('.mfp-product .product-single'));
                // });
                // $popup.defaults.callbacks.open();
            },
            close: function () {
                //$('.mfp-product .swiper').data('slider').destroy();
            }
        }
    }, 'quickview');
});

on('tab.init', function(data) {
    const container = data.element;

    container.querySelectorAll('.tab .nav-link').forEach((element: HTMLElement) => {
        element.addEventListener('click', (e) => {
            e.preventDefault();

            const target = e.currentTarget as HTMLAnchorElement;

            if (target === null) {
                console.error(`tab not found`)
                return
            }

            if (target.classList.contains('active')) {
                return;
            }

            // make panel active
            const href = target.getAttribute('href');

            if (href === null) {
                console.error(`tab missing href`)
                return
            }

            const panel = document.querySelector(href);

            if (panel === null) {
                console.error(`tab panel ${target.href} not found`)
                return
            }

            const panelContainer = panel.parentNode;

            if (panelContainer != null) {
                // remove active
                for (const c of panelContainer.children) {
                    c.classList.remove('active')
                }
            }

            panel.classList.add('active')

            // make tab active
            const tabContainer = target.closest('.nav-tabs');

            if (tabContainer === null) {
                console.error(`tab container not found`)
                return
            }

            // remove active
            for (const c of tabContainer.querySelectorAll('.nav-link')) {
                c.classList.remove('active')
            }

            target.classList.add('active');

            // scroll into view
            tabContainer.scrollIntoView({ behavior: "smooth" });
        })
    })
});

on('mobile-menu.init', function (data) {
    const container = data.element;

    container.querySelectorAll('.mobile-menu li, .toggle-menu li').forEach((element: HTMLElement) => {
        if (element.lastElementChild && (
            element.lastElementChild.tagName === 'UL' ||
            element.lastElementChild.classList.contains('megamenu'))
        ) {
            const span = document.createElement('span');
            span.className = "toggle-btn";
            element.firstElementChild.appendChild(span);
        }

        element.addEventListener('click', (e) => {
            e.preventDefault();

        });
    });

    // initMobileMenu: function () {
    //     $('.mobile-menu li, .toggle-menu li').each(function () {
    //         if (this.lastElementChild && (
    //             this.lastElementChild.tagName === 'UL' ||
    //             this.lastElementChild.classList.contains('megamenu'))
    //         ) {
    //             var span = document.createElement('span');
    //             span.className = "toggle-btn";
    //             this.firstElementChild.appendChild(span);
    //             // this.firstElementChild.insertAdjacentHTML('beforeend', '<span class="toggle-btn"></span>' );
    //         }
    //     });
    //     $('.mobile-menu-toggle').on('click', showMobileMenu);
    //     $('.mobile-menu-overlay').on('click', hideMobileMenu);
    //     $('.mobile-menu-close').on('click', hideMobileMenu);
    //     Wolmart.$window.on('resize', hideMobileMenu);
    // },
})

on('form.control.suggestions.address.init', function (data) {
    interface Address {
        label: string
        countryCode: string
        countryName: string
        stateCode: string
        state: string
        county: string
        city: string
        district: string
        street: string
        postalCode: string
        houseNumber: string
    }

    const container = data.element;
    const input = container.querySelector('input');
    const list = container.querySelector('.form-control-suggestions-list ul');
    const state = new Proxy({
        isOpen: false,
        isLoading: false,
        addresses: [],
    }, {
        get(target, prop, receiver) {
            return Reflect.get(target, prop, receiver);
        },
        set(target, prop, newValue, receiver) {
            switch (prop) {
                case "isOpen":
                    toggleOpen(newValue);
                    break;
                case "isLoading":
                    toggleLoading(newValue);
                    break;
                case "addresses":
                    showAddresses(newValue);
                    break;
            }
            return Reflect.set(target, prop, newValue, receiver);
        },
    });

    let fetchTimeoutID: number|null = null;
    let blurTimeoutID: number|null = null;

    const delay = 0.5;
    const url = data.url;
    const countryFieldSelector: string = data['countrycode-field'];

    const toggleOpen = (state: string) => {
        if (boolval(state)) {
            container.setAttribute('open', '')
        } else {
            container.removeAttribute('open');
        }
    }

    const toggleLoading = (state: string) => {
        if (boolval(state)) {
            container.setAttribute('loading', '');
        } else {
            container.removeAttribute('loading');
        }
    }

    const showAddresses = (data: Address[]) => {
        list.innerHTML = '';

        for(const value of data) {
            const link = document.createElement('a');
            link.innerText = value.label;

            const li = document.createElement('li');
            li.appendChild(link);
            li.addEventListener('click', (e) => {
                e.preventDefault();
                selected(value)
            })
            list.appendChild(li);
        }
    }

    const blur = () => {
        state.isOpen = false;
    }
    const inputFocus = () => {
        clearTimeout(blurTimeoutID);

        if (state.addresses && state.addresses.length > 0) {
            state.isOpen = true;
        }
    }
    const inputBlur = () => {
        clearTimeout(blurTimeoutID);
        blurTimeoutID = setTimeout(blur, 0.5 * 1000);
    }
    const inputStart = () => {
        clearTimeout(fetchTimeoutID);
    }
    const inputEnd = () => {
        clearTimeout(fetchTimeoutID);
        fetchTimeoutID = setTimeout(fetchData, delay * 1000);
    }
    const fetchData = () => {
        const value = input.value;

        state.isLoading = true;

        let requestUrl = url + '?filter=' + value;

        if (countryFieldSelector != null && countryFieldSelector.length != 0) {
            const countryField = document.querySelector<HTMLSelectElement>(countryFieldSelector);
            if (countryField) {
                requestUrl += '&country=' + countryField.value;
            }
        }

        fetch(requestUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
        })
            .then((response) => response.json())
            .then((data) => {
                state.addresses = data;
                state.isOpen = true;
                state.isLoading = false;
            })
            .catch((error) => {
                state.isLoading = false;
                throw new Error(error);
            });
    }
    const selected = (detail: Address) => {
        let address = detail.street || '';
        if (detail.houseNumber) {
            address += ' ' + detail.houseNumber;
        }

        document.querySelector(data['address-field']).value = address;
        document.querySelector(data['locality-field']).value = detail.city || '';
        document.querySelector(data['postalcode-field']).value = detail.postalCode || '';
        document.querySelector(data['administrativearea-field']).value = detail.county || '';
    };

    input.addEventListener('keyup', inputEnd);
    input.addEventListener('keydown', inputStart);
    input.addEventListener('focus', inputFocus);
    input.addEventListener('blur', inputBlur);
})
