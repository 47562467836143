import {on} from "./events";
import {addLoadingIndicator, removeLoadingIndicator} from "./helper";

on('login.form.init', function (data) {
  data.element.querySelectorAll('form').forEach((element) => {
    element.addEventListener("submit", async function(e) {
      e.preventDefault();

      const form = event.target as HTMLFormElement;
      const button = form.querySelector('button[type="submit"]') as HTMLButtonElement;
      addLoadingIndicator(button, true);

      const status = form.querySelector('.submit-status') as HTMLParagraphElement;

      if (status) {
        status.innerText = status.getAttribute('data-text-loading')
        status.className = 'submit-status';
      }

      let alert = form.querySelector('.alert') as HTMLDivElement;

      if (alert) {
        alert.remove();
      }

      // casting to any here to satisfy tsc
      // sending body as x-www-form-url-encoded
      const result = await fetch(form.getAttribute('action'), {
        redirect: 'manual',
        method: form.method,
        body: new FormData(form),
        headers: {
          'Accept': 'application/json'
        },
      })
      .then((response: Response) => response.json())
      .then((json) => json)
      .catch((error) => console.log(error));

      if (status) {
        status.innerText = '';
      }

      if (result.error || result.errors || result.errorCode) {
        removeLoadingIndicator(button, true);

        alert = document.createElement('div');
        alert.className = 'alert alert-icon alert-error alert-bg alert-inline show-code-action mb-2';

        const alertHeader = document.createElement('h4');
        alertHeader.classList.add('alert-title');

        const alertIcon = document.createElement('i');
        alertIcon.classList.add('w-icon-times-circle');

        alertHeader.append(alertIcon);
        alert.append(alertHeader);

        if (result.message) {
          alert.append(document.createTextNode(result.message));
          alert.append(document.createElement('br'));
        }

        if (result.error) {
          alert.append(document.createTextNode(result.error));
          alert.append(document.createElement('br'));
        }

        if (result.errors) {
          for(const fieldHandler in result.errors) {
            if (form.elements[fieldHandler]) {
              alert.append(document.createTextNode(result.errors[fieldHandler]));
              alert.append(document.createElement('br'));
            }
          }
        }

        form.prepend(alert);

        return;
      } else {
        removeLoadingIndicator(button, false);
      }

      if (result.returnUrl) {
        window.location = result.returnUrl;
      }

      if (form.getAttribute('data-redirect')) {
        window.location = form.getAttribute('data-redirect');
      }

      if (form.getAttribute('data-success-message-container')) {
        const successMessage = form.querySelector(form.getAttribute('data-success-message-container'));

        if (successMessage) {
          successMessage.classList.add('alert');
          successMessage.style.display = 'block';
        }
      }
    })
  });
});

on('regionpicker.init', function (data) {
    data.element.querySelectorAll('form').forEach((element) => {
        element.addEventListener("submit", async function (event) {
            event.preventDefault();

            const select = element.getElementsByTagName('select')[0];
            const option = select.options[select.selectedIndex];

            if (!option.hasAttribute('data-site')) {
                window.location = option.getAttribute('data-domain');
                return;
            }

            const form = event.target as HTMLFormElement;
            const button = form.querySelector('button[type="submit"]') as HTMLButtonElement;
            addLoadingIndicator(button, true);

            // casting to any here to satisfy tsc
            // sending body as x-www-form-url-encoded
            const result = await fetch(form.getAttribute('action'), {
                redirect: 'manual',
                method: form.method,
                body: new FormData(form),
                headers: {
                    'Accept': 'application/json'
                },
            })
                .then((response: Response) => response.json())
                .then((json) => json)
                .catch((error) => console.log(error));

            if (result.error || result.errors || result.errorCode) {
                removeLoadingIndicator(button, true);
            }

            if (result.redirect) {
                window.location = result.redirect;
            }

            if (form.getAttribute('data-redirect')) {
                window.location = form.getAttribute('data-redirect');
            }
        })
    });
})
