import {on} from "./events";

const boxes = [],
    timers: {[index: string]: number} = {},
    timerInterval = 200,
    timerClock = function () {
        if (isPaused) {
            return;
        }
        for (const key of Object.keys(timers)) {
            (timers[key] -= timerInterval) <= 0 && close(key);
        }
    };

let $area,
  offset = 0,
  space = 0,
  isPaused = false,
  timerId: boolean | ReturnType<typeof setInterval> = false;

const defaultOption = {
  // info
  productClass: '', // ' product-cart', ' product-list-sm'
  imageSrc: '',
  imageLink: '#',
  name: '',
  nameLink: '#', // 'product.html',
  message: '',
  actionTemplate: '',
  isPurchased: false,

  // option
  delay: 4000, // milliseconds
  space: 20,

  // template

  template: '<div class="minipopup-box">' +
    '<div class="product product-list-sm {{productClass}}">' +
    '<figure class="product-media">' +
    '<a href="{{imageLink}}">' +
    '<img src="{{imageSrc}}" alt="Product" width="80" height="90" />' +
    '</a></figure>' +
    '<div class="product-details">' +
    '<h4 class="product-name"><a href="{{nameLink}}">{{name}}</a></h4>' +
    '{{message}}</div></div>' +
    '<div class="product-action">{{actionTemplate}}</div></div>',
};

function randomIdentifier() {
  return 'minipopup-' + Math.floor(Math.random()*1000000000);
}

function parseTemplate(template, vars, ...args) {
  return template.replace(/\{\{(\w+)\}\}/g, function () {
    return vars[args[1]];
  });
}

export function open(options, callback?) {
  const settings = {...defaultOption, ...options};
  const $boxBody = parseTemplate(settings.template, settings);
  const $box = document.createElement('div');

  $box.id = randomIdentifier();
  $box.className = 'minipopup-box';
  $box.innerHTML = $boxBody;
  $box.addEventListener('mouseenter',function () { pause() });
  $box.addEventListener('mouseleave',function () { resume() });
  $box.addEventListener('touchstart',function (e) { pause(); e.stopPropagation(); });
  $box.addEventListener('mousedown',function (e) { (e.target as HTMLElement).classList.add('focus'); });
  $box.addEventListener('mouseup',function (e) { close((e.target as HTMLElement).id); });

  space = settings.space;

  const $img = $box.querySelector("img");

  // todo refactor this, so that event listeners dont depend on the fact that there is an image or not in the popup
  if ($img) {
    $img.addEventListener('load', () => {
      show($box, settings, callback);
    });
    $img.addEventListener('error', () => {
      console.log('image load error');
    });
  } else {
    show($box, settings, callback);
  }

  // open
  $box.style.top = `${-1 * offset}`;

  $area.append($box);
}

function show($box, settings, callback)
{
  offset += $box.offsetHeight + space;

  $box.classList.add('show');
  if (($box.getBoundingClientRect().top + window.scrollY) - window.pageYOffset < 0) {
    closeOldest();
    $box.style.top = `${- offset + $box[0].offsetHeight + space}`;
  }

  boxes.push($box.id);

  if (!Object.keys(timers).length) {
    timerId = setInterval(timerClock, timerInterval);
  }

  timers[$box.id] = settings.delay;

  callback && callback($box);
}

function closeOldest()
{
  return close(boxes.shift());
}

function close(elementId) {
  const $box = document.getElementById(elementId);

  if (!$box) {
    return;
  }

  // remove timer
  delete(timers[elementId]);
  // timers.splice(index, 1)[0];

  const height = $box.offsetHeight;

  // remove box
  offset -= height + space;

  $box.style.transform = '';
  $box.classList.remove('show');
  pause();

  setTimeout(function () {
    $box.remove();

    // slide down other boxes

    // boxes.forEach(function ($box, i) {
    //   if (i >= index && $box.classList.contains('show')) {
    //     $box.stop(true, true).animate({
    //       top: parseInt($box.css('top')) + height + 20
    //     }, 600, 'easeOutQuint');
    //   }
    // });

    // OR

    // var $next = $box.next();
    // if ($next.length) {
    //   $next.animate({
    //     'margin-bottom': -1 * $box[0].offsetHeight - 20
    //   }, 300, 'easeOutQuint', function () {
    //     $next.css('margin-bottom', '');
    //     $box.remove();
    //   });
    // } else {
    //   $box.remove();
    // }

    resume();
  }, 300);

  // clear timer
  boxes.length || clearTimeout(timerId);
}

function pause () {
  isPaused = true;
}

function resume () {
  isPaused = false;
}

on('document.ready', function (e) {
  const wrapper = document.getElementsByClassName('page-wrapper');

  if (wrapper.length < 1) {
    throw new Error('missing page wrapper');
  }

  $area = document.createElement('div');
  $area.className = "minipopup-area";

  wrapper[0].appendChild($area);
});

on('touchstart', function () {
  resume();
});
