
interface AddressAutocompleteHTMLElement extends HTMLElement {
  checkValidity: () => void;
}

import { defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    url: String,
    delay: {
      type: Number,
      default: 0.5
    },
    name: {
      type: String,
      default: "full_address"
    },
    value: String,
    placeholder: String,
    required: Boolean,
    inputClass: String,
    inputWrapperClass: String,
    countryFieldSelector: String,
  },
  emits: ["valueChanged", "fillAddress"],
  expose: ['checkValidity'],
  setup() {
    const input = ref(null)
    return {
      input
    }
  },
  mounted: function () {
    // expose prop does not work so explicitly add required methods to host.
    const hostNode = (
        this.input.getRootNode() as ShadowRoot | undefined
    )?.host as AddressAutocompleteHTMLElement;

    hostNode.checkValidity = this.checkValidity
  },
  data: function () {
    return {
      isLoading: false,
      isOpen: false,
      addresses: [],
      fetchTimeoutID : null,
      blurTimeoutID : null,
    }
  },
  watch: {
    // inputValue: function (newValue)
  },
  methods: {
    blur() {
      this.isOpen = false;
    },
    inputFocus() {
      clearTimeout(this.blurTimeoutID);

      if (this.addresses && this.addresses.length > 0) {
        this.isOpen = true;
      }
    },
    inputBlur() {
      clearTimeout(this.blurTimeoutID);
      this.blurTimeoutID = setTimeout(this.blur, 0.5 * 1000);
    },
    inputStart() {
      clearTimeout(this.fetchTimeoutID);
    },
    inputEnd() {
      clearTimeout(this.fetchTimeoutID);
      this.fetchTimeoutID = setTimeout(this.fetchData, this.delay * 1000);
    },
    fetchData() {
      this.isLoading = true;

      let requestUrl = this.url + '?filter=' + this.input.value;

      if (this.countryFieldSelector != null && this.countryFieldSelector.length != 0) {
        const countryField = document.querySelector<HTMLSelectElement>(this.countryFieldSelector);
        if (countryField) {
          requestUrl += '&country=' + countryField.value;
        }
      }

      fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
      })
      .then((response) => response.json())
      .then((data) => {
        this.addresses = data;
        this.isOpen = true;
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        throw new Error(error);
      });
    },
    addressSelected(address: any) {
      this.isOpen = !this.isOpen;
      this.$emit('fillAddress', address);
    },
    checkValidity() {
      return this.input.checkValidity();
    },
    reportValidity() {
      return this.input.reportValidity();
    },
  }
});
