import 'virtual:svg-icons-register'

import '../styles/main.scss';

import './wolmart';

// Carousel - Slider:
// https://github.com/NickPiscitelli/Glider.js
// https://barthy-koeln.github.io/scroll-snap-slider/
// https://tannerhodges.github.io/snap-slider/
import {on, emit, handle, shouldHandle} from './events';
import {observer, ready} from './dom';

import './address';
import './shipping';
import './cart';
import './filter';
import './form';
import './gallery';
import './wishlist';
import './compare';
import './translator';
import './user';
import {addLoadingIndicator, removeLoadingIndicator} from "./helper";
import { googleTranslateInit } from './translator';

import './ui';

import './components/livesearch';
// import './components/livesearch/styles.scss';
// import {vanilla, SearchOptions} from './components/livesearch/js-vanilla';

import './components/matrix';
import './components/address-autocomplete/index.ts';

/*
import Embed from './Embed.svelte';

var div = document.createElement('DIV');
var script = document.currentScript;
script.parentNode.insertBefore(div, script);

const embed = new Embed({
	target: div,
	props: { name: 'Svelte component' },
});
*/

// on document change trigger ready events
on('document.changed', function (target) {
    const elements = target.querySelectorAll('.event[data-event=ready]');

    if (!elements || elements.length == 0) {
        return;
    }

    elements.forEach((element) => {
        handle(element, new CustomEvent('document.ready'));
    });
})


on('document.ready', function (e) {
    console.log('document.ready >>!!!', e);

    // vanilla(document.querySelector('.live-search-form input'), {
    //   highlight: true,
    // } as SearchOptions);

    // Google translate
    // const googleTranslateClicked = storage.cookie.get('google-translate');
    // if(googleTranslateClicked) {
    //   googleTranslateLoad();
    // }

    // attach listeners to user interaction to elements with events attached
    document.addEventListener('click', function (event) {
        shouldHandle(event.target, 'click', event);
    });

    document.addEventListener('change', function (event) {
        shouldHandle(event.target, 'change', event);
    });

    document.addEventListener('submit', function (event) {
        shouldHandle(event.target, 'submit', event);
    });

    // following event listeners-handlers will not prevent default event
    document.addEventListener('touchstart', function (event) {
        emit('touchstart', event.target);
    }, { passive: true });

    /*
    // attach listeners to user interaction to elements with events attached
    jQuery(document).on('keyup', '.event[data-event=keyup]', app.event.handle);
    jQuery(document).on('click', '.event[data-event=click]', app.event.handle);
    jQuery(document).on('touchstart', '.event[data-event=click]', tapHandler);
    jQuery(document).on('touchmove', '.event[data-event=click]', tapHandler);
    jQuery(document).on('touchend', '.event[data-event=click]', tapHandler);
    jQuery(document).on('change', '.event[data-event=change]', app.event.handle);
    jQuery(document).on('submit', '.event[data-event=submit]', app.event.handle);

    // document.addEventListener('touchstart', function (event) {
    //     if (event.target.classList.contains('.event[data-event=click]')) {
    //         // Do something
    //     }
    // })
     */

    // trigger ready events
    const elements = document.querySelectorAll('.event[data-event=ready]');

    if (elements && elements.length > 0) {
        elements.forEach((element) => {
            handle(element, new CustomEvent('document.ready'));
        });
    }
})

// observe document mutations
observer({
    target: document,
    callback: function (records) {
        records.forEach((value) => {
            emit('document.changed', value.target);
        })
    }
})

/*
// Init the app.
window[APP_NAME] = (function () {
    sauna.initialize();
    return sauna;
})();
*/

// run stuff on document ready
ready(function (e) {
    emit('document.ready', e);
})

// export global functions
//export { googleTranslateInit };
// window['googleTranslateInit'] = googleTranslateInit;
