import $ from "jquery";
import "devbridge-autocomplete";

// import './../../../__source/wolmart/assets/vendor/jquery.autocomplete/jquery.autocomplete.js';

function LiveSearch(e, $selector) {
  if ('undefined' == typeof $selector) {
    $selector = $('.live-search-form');
  }
  $selector.each(function () {
    const $this = $(this),
      appendTo = $this.find('.live-search-list'),
      $field = $this.find('input[type="text"]'),
      category = $this.find('select[name="category"]'),
      notFoundHtml = $this.find('.live-search-not-found').html(),
      serviceUrl = (this as HTMLFormElement).action;

    let focusBlurTimer = null;

    $this.find('input,select').on('click', () => {
      $this.addClass('is-focused');

      if (focusBlurTimer !== null) {
        clearTimeout(focusBlurTimer);
      }
    });

    $this.find('input,select').on('blur', () => {
      focusBlurTimer = setTimeout(function() {
        $this.removeClass('is-focused');
      }, 0.2*1000)
    });

    $field.devbridgeAutocomplete({
      minChars: 3,
      deferRequestBy: 0.5 * 1000, // in milliseconds
      appendTo: appendTo,
      triggerSelectOnValidInput: false,
      serviceUrl: serviceUrl,
      paramName: 'keywords',
      showNoSuggestionNotice: true,
      noSuggestionNotice: notFoundHtml,
      onSearchStart: function () {
        $this.addClass('is-displaying')
          .removeClass('has-empty-result');

        appendTo.children()
          .eq(0)
          .html('<div class="w-loading"><i></i></div>')
          .css({ position: 'relative', display: 'block' });
      },
      onSelect: function (item) {
        if (item.id != -1) {
          $this.removeClass('is-focused');
          window.location.href = item.url;
        }
      },
      onSearchComplete: function (q, suggestions) {
        if (!suggestions.length) {
          $this.addClass('has-empty-result');
          // if showing showNoSuggestionNotice then need to disable this part
          //appendTo.children().eq(0).html('');
        } else {
          $this.removeClass('has-empty-result');
        }
      },
      beforeRender: function (container) {
        $(container).removeAttr('style');
      },
      onHide: function (container) {
          $this.removeClass('is-displaying')
              .removeClass('has-empty-result');
      },
      formatResult: function (item, currentValue) {
        const pattern = '(' + $.Autocomplete.utils.escapeRegExChars(currentValue) + ')';
        let html = '';
        if (item.img) {
          html += '<img class="search-image" src="' + item.img + '">';
        }
        html += '<div class="search-info">';
        html += '<div class="search-name">' + item.value.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>') + '</div>';
        if (item.price) {
          html += '<span class="search-price">' + item.price + '</span>';
        }
        html += '</div>';

        return html;
      }
    });

    if (category.length) {
      const autocomplete = $field.devbridgeAutocomplete();
      category.on('change', function (e) {
        if (category.val() && category.val() != '0') {
          autocomplete.setOptions({
            serviceUrl: serviceUrl + '?category=' + category.val()
          });
        } else {
          autocomplete.setOptions({
            serviceUrl: serviceUrl
          });
        }

        autocomplete.hide();
        autocomplete.onValueChange();
      });
    }
  });
}

Wolmart.liveSearch = LiveSearch;

$(window).on('wolmart_complete', Wolmart.liveSearch);
