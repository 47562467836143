import {ArrayStorage} from "./storage";
import {Filter, ValueFilter} from "./filter";

export enum SORT_DIRECTION {
    ASC = "ASC",
    DESC = "DESC",
}

export enum SORT_FLAG {
    REGULAR = "REGULAR",
    NUMERIC = "NUMERIC",
    STRING = "STRING",
    LOCALE_STRING = "LOCALE_STRING",
    NATURAL = "NATURAL",
    FLAG_CASE = "FLAG_CASE",
}

export class AggregationSort {

    private direction: SORT_DIRECTION = SORT_DIRECTION.ASC;
    private sortFlags: SORT_FLAG = SORT_FLAG.REGULAR;

    /**
     * Aggregation results sort
     * @param int $direction
     * @param int $sortFlags
     *  Sorting type flags:
     *     SORT_REGULAR - compare items normally; the details are described in the comparison operators section
     *     SORT_NUMERIC - compare items numerically
     *     SORT_STRING - compare items as strings
     *     SORT_LOCALE_STRING - compare items as strings, based on the current locale. It uses the locale, which can be changed using setlocale()
     *     SORT_NATURAL - compare items as strings using "natural ordering" like natsort()
     *     SORT_FLAG_CASE - can be combined (bitwise OR) with SORT_STRING or SORT_NATURAL to sort strings case-insensitively
     *
     * @return int[]
     */
    constructor(direction: SORT_DIRECTION, flags: SORT_FLAG) {
        this.direction = direction;
        this.sortFlags = flags;
    }

    getDirection(): SORT_DIRECTION {
        return this.direction;
    }

    getSortFlags(): SORT_FLAG {
        return this.sortFlags
    }
}

export class AggregationResults {

    /**
     * Sort aggregation result fields and values
     * @param sort
     */
    sort(sort: AggregationSort, result: Map<number|string, any>): Map<number|string, any> {
        const flags = sort.getSortFlags();
        if (sort.getDirection() === SORT_DIRECTION.ASC) {
            result = this.ksort(result, flags);
            for(const [key, val] of result) {
                result.set(key, this.ksort(val, flags))
            }
        } else {
            /*
            krsort($result, $sortFlags);
            foreach ($result as $k => &$v) {
                krsort($v, $sortFlags);
            }
            unset($v);
            */
        }

        return result
    }

    private ksort(obj: Map<number|string, any>, flags: SORT_FLAG): Map<number|string, any> {
        const keys = Array.from(obj.keys()).sort((b, a) => {
                const aFloat = parseFloat(a)
                const bFloat = parseFloat(b)
                const aNumeric = aFloat + '' === a
                const bNumeric = bFloat + '' === b
                if (aNumeric && bNumeric) {
                    return aFloat < bFloat ? 1 : aFloat > bFloat ? -1 : 0
                } else if (aNumeric && !bNumeric) {
                    return 1
                } else if (!aNumeric && bNumeric) {
                    return -1
                }
                return a < b ? 1 : a > b ? -1 : 0
            }
        );
        const sortedObj = new Map<number|string, any>();

        for(const i in keys) {
            sortedObj.set(keys[i], obj.get(keys[i]));
        }

        return sortedObj;
    }
}

export class FiltersSort {
    /**
     * Sort filters by minimum values count, is used for aggregation optimization
     * @param storage
     * @param filters
     */
    byCount(storage: ArrayStorage, filters: Filter[]): any[] {
        const counts = [];

        for(const index in filters) {
            const filter = filters[index];
            const fieldName = filter.getFieldName();

            if (!storage.hasField(fieldName)) {
                counts[index] = 0;
                continue
            }

            const filterValues = filter.getValue();
            const filterValuesCount = [];
            const valuesInFilter = filterValues.length;

            for(const value of filterValues) {
                const cnt = storage.getRecordsCount(fieldName, value)

                if (valuesInFilter >1) {
                    filterValuesCount[value] = cnt;
                }

                if (counts[index] === undefined) {
                    counts[index] = cnt;
                    continue
                }

                if (counts[index] > cnt) {
                    counts[index] = cnt;
                }
            }

            if (valuesInFilter > 1) {
                // sort filter values by records count
                // asort(filterValuesCount)
                // update filers with new values order
                filter.setValue(filterValuesCount.keys())
            }
        }

        // asort(counts);

        const result = [];

        for(const index in counts) {
            result.push(filters[index]);
        }

        return result;
    }
}
