import {emit, on} from "./events";
import {getJsonDataAttribute} from "./helper";
import {main} from "@popperjs/core";
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/dist/photoswipe.css';

import Swiper from 'swiper';
import { Virtual, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar, Parallax, Zoom, Lazy, Controller, A11y, History, HashNavigation, Autoplay, Thumbs, Grid, Manipulation, EffectFade, EffectCube, EffectFlip, EffectCoverflow, EffectCreative, EffectCards, SwiperOptions } from 'swiper/modules';

import 'swiper/css/bundle';

import $ from "jquery";
const jQuery = $;

on('gallery.init', async function(data) {
  jQuery(data.element).magnificPopup({
    delegate: 'a', // the selector for gallery item
    type: 'image',
    gallery: {
      enabled:true
    },
    callbacks: {
      open: function () {
        jQuery('html').css('overflow-y', 'hidden');
        jQuery('body').css('overflow-x', 'visible');
        jQuery('.mfp-wrap').css('overflow', 'hidden auto');
        jQuery('.sticky-header.fixed').css('padding-right', window.innerWidth - document.body.clientWidth);
      },
      close: function () {
        jQuery('html').css('overflow-y', '');
        jQuery('body').css('overflow-x', 'hidden');
        jQuery('.mfp-wrap').css('overflow', '');
        jQuery('.sticky-header.fixed').css('padding-right', '');
      }
    }
  });

  jQuery(data.element).find('.gallery-slider').each(function() {
    let position = 0;

    const $imgLine = jQuery(this).find('ul');
    const $images = $imgLine.find('li');
    const moveStep = $images.first().width();
    const maxPosition = moveStep * $images.length;
    const $left = jQuery(this).parent().find('.button-left');
    const $right = jQuery(this).parent().find('.button-right');

    $left.click(function() {
      if( (maxPosition + position) > 3 * moveStep){
        position = position - moveStep;
        $imgLine.css({'left': position + 'px'});
      }

      if( (maxPosition + position) < 4*moveStep) $left.addClass('disabled');

      $right.removeClass('disabled');
    });
    $right.click(function() {
      if(position < 0){
        position = position + moveStep;
        $imgLine.css({'left': position + 'px'});
      }

      if( (position + moveStep) > 0) $right.addClass('disabled');

      $left.removeClass('disabled');
    });
  });
});

on('gallery.fullscreen', function(data) {
  // http://sylviavanos.nl/CSSBox/

  data.event.preventDefault();

  const $this = jQuery(data.element),
      $product = $this.closest('.product-single'),
      $review = $this.closest('.review-image');

  let $images;

  if ($review.length) {
    $images = $review.find('img');
  } else if ($product.find('.product-single-swiper').length) { // single carousel
    $images = $product.find('.product-single-swiper .swiper-slide:not(.cloned) img:first-child');
  } else if ($product.find('.product-gallery-carousel').length) { // gallery carousel
    $images = $product.find('.product-gallery-carousel .swiper-slide:not(.cloned) img');
  } else { // simple gallery
    $images = $product.find('.product-image img:first-child');
  }

  if ($images.length == 0) {
    return;
  }

  const images = $images.map(function () {
    const $this = jQuery(this);

    return {
      src: $this.attr('data-zoom-image'),
      w: $this.attr('data-zoom-width'),
      h: $this.attr('data-zoom-height'),
      title: $this.attr('alt')
    };
  }).get();

  const swiper = $product.find('.product-single-swiper').data('slider');
  let curIndex = 0;
  if (swiper) {
      // Carousel Type
      curIndex = swiper.slides.findIndex((f) => f.classList.contains('swiper-slide-active'));
  } else {
      // Gallery Type
      curIndex = $product.find('.product-gallery .product-gallery-btn').index($this);
  }

  if ($review.length == 1) {
    const reviewIndex = $review.find('img').index($this);
    curIndex = reviewIndex;
  }

  if (typeof PhotoSwipe !== 'undefined' && typeof PhotoSwipeLightbox !== 'undefined') {
    const photoSwipe = new PhotoSwipeLightbox({
      dataSource: images,
      index: curIndex,
      initialZoomLevel: 'fit',
      pswpModule: PhotoSwipe,
    });

    photoSwipe.init();
    photoSwipe.loadAndOpen(curIndex);
  } else {
    console.error('photo swipe not loaded')
  }
});

on('gallery.swiper', function(data) {
  // https://barthy-koeln.github.io/scroll-snap-slider/
  // https://tannerhodges.github.io/snap-slider/
  // https://nickpiscitelli.github.io/Glider.js/

  const container = data.element;
  let mainSwiperContainer: HTMLElement;

  if (container.classList.contains('swiper-main-container')) {
    mainSwiperContainer = container;
  } else {
    mainSwiperContainer = container.querySelector('.swiper-main-container');
  }

  if (!mainSwiperContainer) {
    console.error('cannot init swiper, main container not found')
    return;
  }

  function beforeInitCleanup(element: Swiper) {
    console.log('beforeInitCleanup');
    const cls = element.wrapperEl.getAttribute('class');
    const match = cls.match(/row|gutter-\w\w|cols-\d|cols-\w\w-\d/g);
    if (match) {
      element.wrapperEl.setAttribute('class', cls.replace(/row|gutter-\w\w|cols-\d|cols-\w\w-\d/g, '').replace(/\s+/, ' '));
    }
    if (!element.wrapperEl.classList.contains('animation-slider')) {
      return;
    }
    const els = element.wrapperEl.children,
      len = els.length;
    for (let i = 0; i < len; ++i) {
      els[i].setAttribute('data-index', i + 1);
    }
  }

  function afterInitCleanup(element: Swiper) {
    console.log('afterInitCleanup');
    for (let i = 0, l = element.slides.length; i < l; i++) {
      if (!element.slides[i].classList.contains('active')) {
        continue;
      }

      const animates = element.slides[i].getElementsByClassName('appear-animate');
      console.log('animates', animates);
      for (let j = animates.length - 1; j >= 0; --j) {
        animates[j].classList.remove('appear-animate');
      }
    }
  }

  const thumbsSwiperContainer = container.querySelector('.swiper-thumbs-container');

  let thumbsSwiper;

  if (thumbsSwiperContainer) {
    let thumbsSwiperOptions = {
      modules: [Navigation, Pagination],
      // a11y: false,
      containerModifierClass: "swiper-",
      freeMode: true,
      // legacy: false,
      navigation: {
        prevEl: thumbsSwiperContainer.querySelector('.swiper-button-prev'),
        nextEl: thumbsSwiperContainer.querySelector('.swiper-button-next'),
        hideOnClick: true,
        disabledClass: 'disabled',
        hiddenClass: 'slider-button-hidden'
      },
      // normalizeSlideIndex: false,
      // pagination: false,
      // slideActiveClass: "slider-slide-active",
      // slideClass: "slider-slide",
      // slideDuplicateClass: "slider-slide-duplicate",
      slidesPerView: 4,
      spaceBetween: 10,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      freeModeSticky: true,
      // wrapperClass: "slider-wrapper",
      on: {
        beforeInit: beforeInitCleanup,
        afterInit: afterInitCleanup,
      }
    } as SwiperOptions

    if (thumbsSwiperContainer.hasAttribute('data-swiper-options')) {
      thumbsSwiperOptions = Object.assign(thumbsSwiperOptions, getJsonDataAttribute(thumbsSwiperContainer, 'data-swiper-options'));
    }

    thumbsSwiper = new Swiper(thumbsSwiperContainer, thumbsSwiperOptions);
//    thumbsSwiper.init();
  }

  let mainSwiperOptions = {
    modules: [Navigation, Pagination, Thumbs],
    loop: true,
    slidesPerView: 1,
    // centeredSlides: true,
    // a11y: false,
    // autoHeight: true,
    containerModifierClass: "swiper-",
    // legacy: false,
    navigation: {
      prevEl: container.querySelector('.swiper-button-prev'),
      nextEl: container.querySelector('.swiper-button-next'),
      hideOnClick: true,
      disabledClass: 'disabled',
      hiddenClass: 'slider-button-hidden'
    },
    // pagination: false,
    // slideActiveClass: "slider-slide-active",
    // slideClass: "slider-slide",
    // slideDuplicateClass: "slider-slide-duplicate",
    thumbs:{
      // slideThumbActiveClass: 'swiper-slide-active',
      swiper: thumbsSwiper
    },
    on: {
      beforeInit: beforeInitCleanup,
      afterInit: afterInitCleanup,
      slideChange: (element: Swiper) => {
        // TODO handle slide show/hide event and trigger animation
        //emit('document.changed', element.slides[element.activeIndex]);
      }
    }
    // wrapperClass: "slider-wrapper",
    // zoom: false
  } as SwiperOptions;

  if (mainSwiperContainer.hasAttribute('data-swiper-options')) {
    mainSwiperOptions = Object.assign(mainSwiperOptions, getJsonDataAttribute(mainSwiperContainer, 'data-swiper-options'));
  }

  const paginationContainer = container.querySelector('.swiper-pagination');

  if (paginationContainer) {
    mainSwiperOptions.pagination = {
      el: paginationContainer,
      clickable: true
    }
  }
  const mainSwiper = new Swiper(mainSwiperContainer, mainSwiperOptions)
//  mainSwiper.init();


  // if animation slider
  if (container.classList.contains('animation-slider')) {
    // if ($el.hasClass('animation-slider')) {
    //   onSliderInitialized.call(this);
    // }=
    mainSwiper.on('resize', () => {
        const activeSlide = mainSwiper.slides[mainSwiper.activeIndex];
        activeSlide.classList.add('show-content');
        activeSlide.removeAttribute("style");
      })
      .on('transitionStart', () => {
        console.log('>>> transitionStart', mainSwiper.activeIndex, mainSwiper.slides[mainSwiper.activeIndex]);
        mainSwiperContainer.dataset['translateFlag'] = '1';
        mainSwiperContainer.dataset['prev'] = mainSwiperContainer.dataset['next'];

        // var self = this,
        //   $el = $(this.wrapperEl);
        // self.translateFlag = 1;
        // self.prev = self.next;
        // $el.find('.swiper-slide .slide-animate').each(function () {
        //   var $animation_item = $(this),
        //     settings = $.extend(true, {}, Wolmart.animationOptions, Wolmart.parseOptions($animation_item.data('animation-options')));
        //   $animation_item.removeClass(settings.name);
        // });
      })
      .on('transitionEnd', () => {
        console.log('>>> transitionEnd', mainSwiper.activeIndex, mainSwiper.slides[mainSwiper.activeIndex]);

        if (mainSwiperContainer.dataset['translateFlag'] !== '1') {
          return;
        }

        mainSwiperContainer.dataset['translateFlag'] = '0';
        mainSwiperContainer.dataset['next'] = mainSwiper.activeIndex;

        const activeSlide = mainSwiper.slides[mainSwiper.activeIndex];

        activeSlide.classList.remove('show-content');

        if (mainSwiperContainer.dataset['pref'] == mainSwiperContainer.dataset['next']) {
          //$el.find('.swiper-slide').eq(this.slider.activeIndex).find('.slide-animate').addClass('show-content');
          activeSlide.classList.add('show-content');
          return;
        }

        // $el.find('.show-content').removeClass('show-content');
        activeSlide.classList.remove('show-content');

        /* clear all animations that are running. */
        if (activeSlide.classList.contains('animation-slider')) {
          // for (var i = 0; i < self.timers.length; i++) {
          //   Wolmart.deleteTimeout(self.timers[i]);
          // }
          // self.timers = [];
        }

        activeSlide.querySelectorAll('.slide-animate').forEach((item)=> {
          const $animation_item = item,
            settings = Object.assign({
              name: 'fadeIn',
              duration: '1.2s',
              delay: '.2s'
            }, getJsonDataAttribute(item, 'animation-options')),
            delay = settings.delay,
            aniName = settings.name;
          let duration = settings.duration;

          $animation_item.style.animationDuration = duration;
          $animation_item.style.animationDelay = delay;
          $animation_item.style.transitionProperty = 'visibility, opacity';
          $animation_item.style.transitionDelay = delay;
          $animation_item.style.transitionDuration = duration;
          $animation_item.classList.add(aniName);

          duration = duration ? duration : '0.75s';

          setTimeout(() => {
            $animation_item.style.transitionProperty = '';
            $animation_item.style.transitionDelay = '';
            $animation_item.style.transitionDuration = '';
            $animation_item.classList.add('show-content');
          }, (delay ? Number((delay).slice(0, -1)) * 1000 + Number((duration).slice(0, -1)) * 500 : Number((duration).slice(0, -1)) * 500))

          // var temp = Wolmart.requestTimeout(function () {
          //   $animation_item.css('transition-property', '');
          //   $animation_item.css('transition-delay', '');
          //   $animation_item.css('transition-duration', '');
          //   $animation_item.addClass('show-content');
          //   self.timers.splice(self.timers.indexOf(temp), 1)
          // }, (delay ? Number((delay).slice(0, -1)) * 1000 + Number((duration).slice(0, -1)) * 500 : Number((duration).slice(0, -1)) * 500));
          // self.timers.push(temp);
        });
      });
  }

  // const fullScreenBtn = container.querySelector('.product-image-full');
  //
  // if (fullScreenBtn) {
  //     fullScreenBtn.addEventListener("click", (event) => {
  //         event.stopPropagation()
  //
  //         const fullScreenTarget = document.querySelector(fullScreenBtn.getAttribute('data-params-target'));
  //
  //         if (fullScreenTarget.classList.contains('swiper-fullscreen')) {
  //             document.body.classList.remove('modal-fullscreen');
  //             fullScreenTarget.classList.remove('swiper-fullscreen');
  //             return;
  //         }
  //
  //         document.body.classList.add('modal-fullscreen');
  //         fullScreenTarget.classList.add('swiper-fullscreen');
  //
  //         setTimeout(function () {
  //             mainSwiper.update();
  //             thumbsSwiper.update();
  //         }, 200);
  //     });
  // }

  /*
  // full screen button
  https://gist.github.com/scrawlon/647a109044c9d04fa1af900e2fdd61c0
   */
});
