const Observer = window.MutationObserver || window.WebKitMutationObserver;

function ready(listener: (EventListenerOrEventListenerObject) => any) {
  // Sanity check
  if ( typeof listener !== 'function' ) return;

  // If document is already loaded, run method
  if ( document.readyState === 'complete'  ) {
    return listener.call(document, new Event('DOMContentLoaded'));
  }

  // Otherwise, wait until document is loaded
  document.addEventListener( 'DOMContentLoaded', listener, false );
}

interface ObserverProps {
  target?: Element | Node | null;
  options?: MutationObserverInit;
  callback?: MutationCallback;
}

function observer({target, options = {attributes: false, childList: true, subtree: true }, callback}: ObserverProps) {
  if (!Observer) {
    return;
  }
  const observer = new Observer((mutationRecord, mutationObserver) => {
    callback?.(mutationRecord, mutationObserver);
  });
  observer.observe(target, options);
  return observer;
}

export { ready, observer }
