import {on, emit} from "./events";

async function submit(form:HTMLFormElement) {
  return await fetch('/', {
      method: form.method,
      body: new FormData(form),
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
    })
    .then((response: Response) => response.json())
    .then((json) => {
      if (!json.success) {
        throw new Error(json.error);
      }

      return json;
    })
    .catch((error) => console.log("error", error));
}

function show(identifier, result, wrapper, added) {
  // Step 1: make sure we have the popup container, if not then create from template
  let container = document.querySelector('.page-wrapper > .compare-popup');

  if (!container) {
    const containerTpl = document.getElementById('compare-popup') as HTMLTemplateElement;

    if (!containerTpl) {
      return;
    }

    const popup = containerTpl.content.cloneNode(true) as DocumentFragment;
    container = popup.firstElementChild;

    document.getElementsByClassName('page-wrapper')[0].append(popup);
  }

  const list = container.querySelector('.compare-product-list') as HTMLUListElement;

  // item has been removed, so find it and remove it
  if (!added) {
      container.classList.add('show');

      const filled = list.querySelector('[data-identifier="' + identifier + '"]');
      filled.innerHTML = '';
      filled.removeAttribute('data-identifier');
      filled.classList.remove('filled');

      return;
  }

  // Step 2: find a free slot in the list where we can add our new item, if all occupied then hide first one and add a new to the end
  // we start with 1 because we will add one
  let filled = 0;
  let slot = null;

  for (let i = 0, l = list.children.length; i < l; i++) {
    const item = list.children[i] as HTMLLIElement;

    if (item.classList.contains('filled')) {
      filled++;
      continue;
    }

    slot = item;
    break;
  }

  if (slot == null) {
    // TODO hide first visible, add new to the end
    slot = document.createElement('li');
  } else {
    filled++;
  }

  // Step 3: create preview from template and add it to the free slot
  const itemTpl = document.getElementById('compare-popup-item') as HTMLTemplateElement;

  if (!itemTpl) {
    return;
  }

  const itemView = itemTpl.content.cloneNode(true) as DocumentFragment;

  const props = itemView.querySelectorAll('[itemprop]');

  props.forEach(function (prop) {
    const propKey = prop.getAttribute('itemprop');
    const propValue = wrapper.querySelector('[itemprop="' + propKey + '"]');

    if (!propValue) {
      return;
    }

    if (propValue instanceof HTMLImageElement) {
      prop.setAttribute('src', propValue.getAttribute('src'));
    } else if (propValue instanceof HTMLAnchorElement) {
      prop.setAttribute('href', propValue.getAttribute('href'));
    } else {
      prop.innerHTML = propValue.innerHTML;
    }
  });

  slot.append(itemView);
  slot.classList.add('filled');
  slot.setAttribute('data-identifier', identifier);

  // update count
  const counter = container.querySelector('.compare-count');
  counter.innerHTML = counter.getAttribute('data-text').replace('{items}', String(filled));

  container.classList.add('show');

  console.log("show result", identifier, container, result, wrapper);
}

on('compare.toggle', async function (data) {
  console.log("Compare toggle ADD start", data);

  data.event.preventDefault();

  const button = data.element as HTMLElement;
  const form = document.getElementById('compare-product-' + data.identifier) as HTMLFormElement;
  const product = form.closest('.product-wrap');
  const identifier = data['variant-id'];

  button.classList.add('load-more-overlay');
  button.classList.add('loading');

  const result = await submit(form);
  const added = !button.classList.contains('added');

  if (added) {
    button.classList.remove('w-icon-compare');
    button.classList.add('added');
    button.classList.add('w-icon-check-solid');
  } else {
    button.classList.remove('added');
    button.classList.remove('w-icon-check-solid');
    button.classList.add('w-icon-compare');
  }

  button.classList.remove('load-more-overlay');
  button.classList.remove('loading');

  console.log("Compare toggle show", data, button, form, result, product, identifier);

  show(identifier, result, product, added);
});

on('compare.close', function() {
  const container = document.querySelector('.page-wrapper > .compare-popup');

  if (!container) {
    return;
  }

  container.classList.remove('show');
});

on('compare.remove', function (data) {
  if (!data.col) {
    return;
  }

  document.querySelectorAll(data.col).forEach((e) => { e.remove(); });

  if (document.querySelectorAll('.compare-product').length == 0) {
    location.reload();
  }
});

on('compare.clean', function (data) {
  const container = document.querySelector('.page-wrapper > .compare-popup');

  if (!container) {
    return;
  }

  const list = container.querySelector('.compare-product-list') as HTMLUListElement;

  for (let i = 0, l = list.children.length; i < l; i++) {
    const item = list.children[i] as HTMLLIElement;

    if (item.classList.contains('filled')) {
      item.innerHTML = '';
      item.classList.remove('filled');
    }
  }

  emit('compare.close', data)
});
