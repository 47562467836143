export function intval(a: any, b: number) {
  let c;
  return c = typeof a, c === "boolean" ? a ? 1 : 0 : c === "string" ? (c = parseInt(a, b || 10), isNaN(c) || !isFinite(c) ? 0 : c) : c === "number" && isFinite(a) ? Math.floor(a) : 0
}

export function trim(value: string) {
  return value.replace(/^\s+|\s+$/g, '');
}

export function boolval(value: any) {
  if (typeof(value) === 'string'){
    value = trim(value).toLowerCase();
  }
  switch(value){
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}

export function getDataAttributes(node: HTMLElement, prefix: string) {
  const data: any = {}, dataAttr = new RegExp('^data-' + (typeof (prefix) != 'undefined' ? prefix + '-' : '') + '(.+)$');

  const attributes = node.getAttributeNames();

  for (const name of attributes) {
    if (dataAttr.test(name) ) {
      const key = name.match(dataAttr)![1];
      data[key] = node.getAttribute(name);
    }
  }

  return data;
}

export function getJsonDataAttribute(node: HTMLElement, attribute: string) {
  const data = node.getAttribute(attribute);
  if (!data) {
    return {};
  }
  return JSON.parse(data.replace(/'/g, '"').replace(';', ''))
}

export function addLoadingIndicator(element: HTMLElement|HTMLFormElement, disable = false) {
  if (element instanceof HTMLFormElement) {
    const button = element.querySelector('button[type="submit"]');

    if (button instanceof HTMLButtonElement) {
      addLoadingIndicatorToButton(button, disable);
    }
  }

  if (element instanceof HTMLButtonElement) {
    addLoadingIndicatorToButton(element, disable);
  }

  if (element instanceof HTMLAnchorElement) {
    addLoadingIndicatorToAnchor(element, disable);
  }

  if (element instanceof HTMLDivElement) {
    addLoadingIndicatorToBlock(element, disable);
  }
}

function addLoadingIndicatorToButton(element: HTMLButtonElement, disable = false) {
  const loader = document.createElement('div');
  loader.classList.add('w-loading');
  loader.classList.add('small');
  loader.append(document.createElement('i'));
  element.style.position = 'relative';
  element.append(loader);

  if (disable) {
    element.disabled = true;
  }
}

function addLoadingIndicatorToAnchor(element: HTMLAnchorElement, disable = false) {
  element.classList.add('load-more-overlay', 'loading');
}

function addLoadingIndicatorToBlock(element: HTMLDivElement, disable = false) {
  const loader = document.createElement('div');
  loader.classList.add('w-loading');
  loader.classList.add('small');
  loader.append(document.createElement('i'));
  element.append(loader);
}

export function removeLoadingIndicator(element: HTMLFormElement|HTMLElement, enable = false) {
  if (element instanceof HTMLFormElement) {
    const button = element.querySelector('button[type="submit"]');

    if (button instanceof HTMLButtonElement) {
      removeLoadingIndicatorFromButton(button, enable);
    }
  }

  if (element instanceof HTMLButtonElement) {
    removeLoadingIndicatorFromButton(element, enable);
  }

  if (element instanceof HTMLAnchorElement) {
    removeLoadingIndicatorToAnchor(element, enable);
  }

  if (element instanceof HTMLDivElement) {
    removeLoadingIndicatorToBlock(element, enable);
  }
}

function removeLoadingIndicatorFromButton(element: HTMLButtonElement, enable = false) {
  element.style.position = 'unset';

  const indicator = element.querySelector('.w-loading');

  if (indicator) {
    indicator.remove();
  }

  if (enable) {
    element.disabled = false;
  }
}

function removeLoadingIndicatorToAnchor(element: HTMLAnchorElement, enable = false) {
  element.classList.remove('load-more-overlay', 'loading');
}

function removeLoadingIndicatorToBlock(element: HTMLDivElement, enable = false) {
  const indicator = element.querySelector('.w-loading');

  if (indicator) {
    indicator.remove();
  }
}
