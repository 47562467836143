import {emit, on} from "./events";
import {boolval} from "./helper";

function toggleShippingBlock(blockSelector, shippingMethod) {
  const shippingAddressRequired = boolval(shippingMethod.getAttribute('data-shipping-address-required'));
  const block = document.querySelector(blockSelector);
  if (shippingAddressRequired) {
      block.style.display = 'block';
  } else {
      block.style.display = 'none';
      block.querySelectorAll('[data-required]').forEach((e) => e.required = false);
  }
}

function toggleShippingOptions(blockSelector, shippingMethod) {
  document.querySelectorAll(blockSelector).forEach((e) => {
    e.style.display = 'none';
  });
  shippingMethod.closest('.shipping-method-wrapper').querySelector(blockSelector).style.display = 'block';
}

on('shipping.form.init', function (data) {
  data.element.querySelectorAll(data['shipping-field']).forEach((field) => {
    field.addEventListener('change', (event) => {
      toggleShippingBlock(data['shipping-block'], event.target);
      toggleShippingOptions(data['shipping-options'], event.target);
    });
  });

  const selectedMethod = data.element.querySelector(data['shipping-field'] + ':checked');

  if (selectedMethod) {
    toggleShippingBlock(data['shipping-block'], selectedMethod);
    toggleShippingOptions(data['shipping-options'], selectedMethod);
  }
});
