import {open} from './minipopup';

export function showErrors(errors) {
    let errorMessage = ''

    for(const lineItem in errors) {
        if (typeof errors[lineItem] === 'string') {
            errorMessage += errors[lineItem];
        } else {
            errorMessage += errors[lineItem].join('\n');
        }
    }

    open({
        delay: 10 * 1000,
        template: '<div class="alert alert-warning alert-round alert-inline">' + errorMessage + '</div>'
    })
}
