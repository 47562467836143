import {Filter} from "./filter";
import {ArrayStorage} from "./storage";

export class Scanner {
    private array_intersect_key (arr1: Map<number,boolean>, ...arrg: Map<number,boolean>[]): Map<number,boolean> { // eslint-disable-line camelcase
        //  discuss at: https://locutus.io/php/array_intersect_key/
        // original by: Brett Zamir (https://brett-zamir.me)
        //      note 1: These only output associative arrays (would need to be
        //      note 1: all numeric and counting from zero to be numeric)
        //   example 1: var $array1 = {a: 'green', b: 'brown', c: 'blue', 0: 'red'}
        //   example 1: var $array2 = {a: 'green', 0: 'yellow', 1: 'red'}
        //   example 1: array_intersect_key($array1, $array2)
        //   returns 1: {0: 'red', a: 'green'}
        const retArr = new Map
        const argl = arguments.length
        const arglm1 = arrg.length
        let k1 = ''
        let arr = {}
        let i = 0
        let k = ''
        arr1keys: for (k1 in arr1) { // eslint-disable-line no-labels
            if (!Object.prototype.hasOwnProperty.call(arr1, k1)) {
                continue
            }
            arrs: for (i = 1; i < argl; i++) { // eslint-disable-line no-labels
                // eslint-disable-next-line prefer-rest-params
                arr = arguments[i]
                for (k in arr) {
                    if (!Object.prototype.hasOwnProperty.call(arr, k)) {
                        continue
                    }
                    if (k === k1) {
                        if (i === arglm1) {
                            retArr[k1] = arr1[k1]
                        }
                        // If the innermost loop always leads at least once to an equal value,
                        // continue the loop until done
                        continue arrs // eslint-disable-line no-labels
                    }
                }
                // If it reaches here, it wasn't found in at least one array, so try next value
                continue arr1keys // eslint-disable-line no-labels
            }
        }
        return retArr
    }

    findRecordsMap(storage: ArrayStorage, filters: Filter[], inputRecords: Map<number,boolean>): Map<number,boolean> {
        if (filters.length == 0) {
            return this.findInput(storage, inputRecords)
        }

        const data = storage.getData()

        for(const filter of filters) {
            const fieldName = filter.getFieldName();

            if (!data.has(fieldName)) {
                return new Map;
            }

            inputRecords = filter.filterInput(data.get(fieldName), inputRecords)

            if (inputRecords.size == 0) {
                return new Map;
            }
        }
        return inputRecords;
    }

    private findInput(storage: ArrayStorage, inputRecords: Map<number,boolean>): Map<number,boolean> {
        const total = this.getAllRecordIdMap(storage)
        if (inputRecords.size > 0) {
            return this.array_intersect_key(total, inputRecords)
        }
        return total;
    }

    private getAllRecordIdMap(storage: ArrayStorage): Map<number,boolean> {
        const result = new Map;

        for (const values of Array.from(storage.getData().values())) {
            for(const list of Array.from(values.values())) {
                for(const v of list) {
                    result.set(v, true);
                }
            }
        }

        return result;
    }
}
