import {boolval, getDataAttributes} from './helper';

/**
 * @description listeners repo
 * @private
 */
const listeners = new Map();

/**
 * @description add listener
 * @public
 * @param {string} name event name
 * @param {function} func function to call
 * @returns {function} unsubscribe function
 */
function on(name, func) {
  if (!listeners.has(name)) listeners.set(name, []);
  listeners.get(name).push(func);
  return () => unsubscribe(name, func);
}

/**
 * @description like "on" but just run once
 * @public
 * @param {string} name event name
 * @param {function} func function to call
 * @returns {function} unsubscribe function
 */
function once(name, func, ...args) {
  const unsubscribe = on(name, function () {
    func(undefined, ...args);
    unsubscribe();
  });
  return unsubscribe;
}

/**
 * @description dispatch all listener
 * @public
 * @param {string} name event name
 * @param {any} arg arguments to pass to listeners
 * @returns {array} returns all listener can return data
 */
function emit(name, arg) {
  const returns = [];
  if (listeners.has(name))
    listeners.get(name).forEach((func) => {
      if (func) returns.push(func(arg));
    });
  return returns;
}

/**
 * @description unsubscribe listener
 * @public
 * @param {string} name event name
 * @param {function} func the function that you want to unsubscribe If not defined, all listeners will be canceled
 * @returns {undefined} nothing
 */
function unsubscribe(name, func) {
  if (!listeners.has(name)){
    return;
  }

  if (func)
    listeners.set(
      name,
      listeners.get(name).filter((f) => f !== func)
    );
  else listeners.delete(name);
}

const isMatches = function(el, selector) {
  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
};

function shouldHandle(element, type, event) {
  let target = null;

  if (isMatches(element, `.event[data-event="${type}"]`)) {
    target = element;
  } else {
    const closest = element.closest(`.event[data-event="${type}"]`);

    if (closest != null) {
      target = closest;
    }
  }

  if (target != null) {
    handle(target, event);
  }
}

function handle(element, event) {
  if(element.hasAttribute('disabled')) {
    event.preventDefault();
    return;
  }

  const action = element.getAttribute('data-event');

  if(action === 'ready') {
    if( typeof element.getAttribute('data-event-single') !== 'undefined' ) {
      element.classList.remove('event');
    }
    if( typeof element.getAttribute('data-event-multiple') === 'undefined' ) {
      element.classList.remove('event');
    }
  }

  const event_default = boolval(element.getAttribute('data-event-default'));

  if(event !== undefined && typeof(event) == 'object' && event_default === false ) {
    event.preventDefault();
  }

  const event_name = element.getAttribute('data-event-trigger');
  const event_params = getDataAttributes(element, 'params');

  if(event_name && event_name.length > 0) {
    emit(event_name, Object.assign({}, { element: element, event: event }, event_params) );
  }

  const form_name = element.getAttribute('data-event-form');

  if(form_name && form_name.length > 0) {
    document.forms[form_name].submit();
  }

  return !(event_default === false);
}

export { on, emit, once, unsubscribe, shouldHandle, handle }
