import {emit, on} from "./events";
import {addLoadingIndicator, removeLoadingIndicator} from "./helper";
import SelectTags from "./components/select-tags";
import Range from "./components/range";
import Multirange from "./components/multirange";
import {showErrors} from "./errors";
import {open} from "./minipopup";

function GetFormData(form) {
  return [].slice.call(form.querySelectorAll('[name]'))
    .filter(function (element, index, array) {
      if (element.disabled) {
        return false;
      }

      if (element instanceof HTMLSelectElement) {
        return [].slice.call(element.selectedOptions).filter((e) => e.value.length > 0).length > 0;
      } else if (element instanceof HTMLButtonElement) {
        return false;
      } else if (element instanceof HTMLInputElement && ['file', 'reset', 'submit', 'button'].indexOf(element.type) > -1) {
        return false;
      } else if (element instanceof HTMLInputElement && element.type == 'range') {
        if (element.hasAttribute('data-default')) {
          return (element.value != element.getAttribute('data-default'));
        } else {
          return (element.value != element.min);
        }
      } else if (element instanceof HTMLInputElement && (element.type == 'radio' || element.type == 'checkbox')) {
        return element.checked;
      }

      return (element.value.length > 0);
    }).reduce(function (carry, element) {
      const key = element.name;
      let value;

      if (element instanceof HTMLSelectElement) {
        value = [].slice.call(element.selectedOptions).reduce(function (selection, option) { return [].concat(selection, option.value); }, []);
      } else {
        value = element.value;
      }

      if (key in carry) {
        console.log("EXISTS");
        if (!Array.isArray(carry[key])) {
          carry[key] = [carry[key]];
        }
        if (Array.isArray(value)) {
          carry[key] = [].concat(carry[key], value);
        } else {
          carry[key].push(value);
        }
        console.log("carry", carry);
        return carry;
      } else {
        console.log("not in carry", carry, key, value);
      }

      // if (element.name in carry) {
      //   // get current value
      //   var value = carry[element.name];
      //
      //   if (!Array.isArray(value)) {
      //     // convert existing value to array
      //     value = value.split(',');
      //   }
      //
      //   // reset original value to prevent duplicates
      //   carry[element.name] = null;
      //
      //   // push new value to the (newly created) array
      //   value.push(element.value);
      //
      //   // build new object
      //   obj = build([], element.name, value);
      // }

      return {
        ...carry,
        [key]: value,
      };
    }, {});
}

function EncodeFormData(formData) {
  return [].slice.call(Object.entries(formData)).reduce(function (carry, value) {
    return carry + (carry.length > 0 ? '&' : '') + value[0] + '=' + encodeURIComponent(value[1]);
  }, '');
}

function serializeForm(form) {
  return [].slice.call(form.querySelectorAll('[name]'))
    .filter(function (element, index, array) {
      if (element instanceof HTMLSelectElement) {
        return [].slice.call(element.selectedOptions).filter((e) => e.value.length > 0).length > 0;
      } else if (element instanceof HTMLInputElement && element.type == 'range') {
        return (element.value != element.min);
      } else if (element instanceof HTMLInputElement && (element.type == 'radio' || element.type == 'checkbox')) {
        return element.checked;
      }

      return (element.value.length > 0);
    })
    .reduce(function (carry, input) {
      return carry + (carry.length > 0 ? '&' : '') + input.name + '=' + encodeURIComponent(input.value);
    }, '');
}

on('form.submit', async function (e) {
  console.log("FORM", "submit form now", e, e.event.target);

  e.event.preventDefault();

  let form: HTMLFormElement;

  if (e.form !== undefined) {
    form = document.getElementById(e.form) as HTMLFormElement;
  } else if (e.element instanceof HTMLFormElement) {
    form = e.element;
  } else if (e.event.target instanceof HTMLFormElement) {
    form = e.event.target;
  } else {
    form = e.event.target.closest('form');

    if (form === null) {
      throw new Error("target form not set");
    }
  }

  if (form === null || !(form instanceof HTMLFormElement)) {
    throw new Error("target form not set or not form");
  }

  let formData = {};

  if (e.reset !== true) {
    formData = GetFormData(form);
  }

  if (e.event.target instanceof HTMLButtonElement && e.event.target.name.length > 0 && e.event.target.value.length > 0) {
    formData[e.event.target.name] = e.event.target.value;
  }

  console.log('form data', Object.fromEntries((new FormData(form))), formData, EncodeFormData(formData));

  if (form.getAttribute('method') === 'get') {
    // get the action, if action field is specified then use its value
    let action = form.action;
    if (action instanceof HTMLInputElement) {
      action = action.value;
    }

    window.location = action + (action.indexOf('?') >= 0 ? '&' : '?') + EncodeFormData(formData);
    return
  }

  const element = e.element;

  addLoadingIndicator(element);

  console.log('form submit to', form.getAttribute('action'), new FormData(form));

  const result = await fetch(form.getAttribute('action'), {
    method: form.getAttribute('method'),
    body: new FormData(form),
    headers: {
      'Accept': 'application/json'
    },
  })
  .then((response: Response) => {
    removeLoadingIndicator(element);

    return response.json()
  })
  .then((json) => {
    console.log("json", json);

    if (json.modelName) {
      console.log('model.update.' + json.modelName);
      emit('model.update.' + json.modelName, {
        form: form,
        element: element,
        model: json
      });
      return json;
    }

    if (json.message) {
      open({
        delay: 10 * 1000,
        template: '<div class="alert alert-success alert-round alert-inline">' + json.message + '</div>'
      })
    }

    if (json.error) {
      throw new Error(json.error);
    }

    if (json.exception) {
      throw new Error(json.exception);
    }

    if (json.errors) {
      showErrors(json.errors);
    }

    if (json.redirect !== undefined) {
      window.location = '/' + json.redirect;
      return;
    }

    return json;
  })
  .catch((error) => console.error("error", error));

  if (e.success) {
    emit(e.success, Object.assign(e, {result: result}));
  }
});

on('form.select.tags', function (e) {
  const selectElement = e.element;

  if (!(selectElement instanceof HTMLSelectElement)) {
    throw new Error('select tags must be initialized on select element');
  }

  SelectTags(selectElement);
});

on('form.range', function (e) {
  const wrapperElement = e.element;

  if (!(wrapperElement instanceof HTMLDivElement)) {
    throw new Error('range must be initialized on wrapper div element');
  }

  const inputs = wrapperElement.querySelectorAll('input[type="range"]')

  if (inputs.length != 1) {
    throw new Error('you must have one range input inside of the wrapper div element');
  }

  Range(wrapperElement, inputs[0]);
});

on('form.range.multiple', function (e) {
  const wrapperElement = e.element;

  if (!(wrapperElement instanceof HTMLDivElement)) {
    throw new Error('range must be initialized on wrapper div element');
  }

  const inputs = wrapperElement.querySelectorAll('input[type="range"]')

  if (inputs.length != 2) {
    throw new Error('you must have two range inputs inside of the wrapper div element');
  }

  Multirange(wrapperElement, inputs[0], inputs[1]);
});
