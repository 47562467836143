import {Indexer} from "./indexer";

export interface Storage {
    /**
     * Add record to index
     *
     * @param recordId
     * @param recordValues
     * @return boolean
     */
    addRecord(recordId: string, recordValues: Map<string, string|string[]>): boolean

    /**
     * Get field data section from index
     * @param fieldName
     * @return Map<int|string,array<int>>
     */
    getFieldData(fieldName: string): Map<string, string[]>

    /**
     * Check if field exists
     * @param fieldName
     * @return boolean
     */
    hasField(fieldName: string): boolean

    /**
     * Get facet data.
     * @return array<int|string,array<int|string,array<int>|\SplFixedArray<int>>>
     */
    getData(): Map<string, Map<string, string[]>>

    /**
     * Export facet index data.
     * @return array<int|string,array<int|string,array<int>>>
     */
    export(): Map<string, Map<string, string[]>>

    /**
     * Load saved data
     * @param data
     * @return void
     */
    setData(data: Map<string, Map<string, string[]>>): void

    /**
     * Optimize index structure
     * @return void
     */
    optimize(): void

    /**
     * Delete record from index
     * @param recordId
     * @return bool - success flag
     */
    deleteRecord(recordId: string): boolean;

    /**
     * Update record data
     * @param recordId
     * @param recordValues -  ['fieldName'=>'fieldValue','fieldName2'=>['val1','val2']]
     * @return bool - success flag
     */
    replaceRecord(recordId: string, recordValues: Map<string, string|string[]>): boolean;

    /**
     * Add specialized indexer for field
     * @param fieldName
     * @param indexer
     */
    addIndexer(fieldName: string, indexer: Indexer): void;

    /**
     * @param fieldName
     * @param value
     * @return int
     */
    getRecordsCount(fieldName: string, value: any): number;
}

export class ArrayStorage implements Storage {
    // map of field names having a map of field values having an array of record ids
    data = new Map<string, Map<string, string[]>>();
    indexers = [];

    //
    addRecord(recordId: string, recordValues: Map<string, string|string[]>): boolean {
        recordId = recordId.toString()

        for(const fieldName in recordValues) {
            let values:any = recordValues[fieldName];

            if (!Array.isArray(values)) {
                values = [values];
            }

            values = values.filter((value: any, index: number, array: string[]) => {
                return array.indexOf(value) === index;
            })

            if (fieldName in this.indexers) {
                if (!this.data.has(fieldName)) {
                    this.data.set(fieldName, new Map());
                }
                if (!this.indexers[fieldName].add(this.data.get(fieldName), recordId, values)) {
                    return false;
                }
            } else {
                for(const key in values) {
                    const value: any = values[key].toString();

                    if (!this.data.has(fieldName)) {
                        this.data.set(fieldName, new Map());
                    }

                    if (!this.data.get(fieldName).has(value)) {
                        this.data.get(fieldName).set(value, []);
                    }

                    this.data.get(fieldName).get(value).push(recordId);
                }
            }
        }

        return true;
    }

    getData(): Map<string, Map<string, string[]>> {
        return this.data
    }

    export(): Map<string, Map<string, string[]>> {
        return this.data
    }

    setData(data: Map<string, Map<string, string[]>>): void {
        this.data = data;
    }

    getFieldData(fieldName: string): Map<string, string[]> {
        return this.data.get(fieldName) ?? new Map();
    }

    addIndexer(fieldName: string, indexer: any): void {
        this.indexers[fieldName] = indexer
    }

    getRecordsCount(fieldName: string, value: any): number {
        if (!this.data.get(fieldName).get(value)) {
            return 0;
        }

        return this.data.get(fieldName).get(value).length;
    }

    hasField(fieldName: string): boolean {
        if (!this.data.has(fieldName)) {
            return false
        }

        return this.data.get(fieldName).size > 0
    }

    getValues(): any[] {
        const result = {};
        for(const fieldName in this.data) {
            for(const key in this.data.get(fieldName)) {
                if (!result[fieldName]) {
                    result[fieldName] = {};
                }
                result[fieldName][key] = true;
            }
        }
        return result;
    }

    getValuesCount(): any[] {
        const result = [];
        for(const fieldName in this.data) {
            for(const key in this.data.get(fieldName)) {
                if (!result[fieldName]) {
                    result[fieldName] = {};
                }
                result[fieldName][key] = this.data.get(fieldName).get(key).length;
            }
        }
        return result;
    }

    optimize(): void {
        for(const fieldName in this.data) {
            let valueCounts = {};

            for (const value in this.data.get(fieldName)) {
                valueCounts[value] = this.data.get(fieldName).get(value).length

                // sort records by id asc
                this.data[fieldName][value].sort((a,b) => {
                    if (a > b) return 1;
                    else if (a < b) return -1;
                    else return 0;
                })
            }

            // sort values by records count and retain key
            const valArr = [];
            for (const k in valueCounts) {
                valArr.push([k, valueCounts[k]])
            }
            valArr.sort((a, b) => {
                if (a[1] > b[1]) return 1;
                else if (a[1] < b[1]) return -1;
                else return 0
            })
            valueCounts = {}
            for (let i = 0, valArrLen = valArr.length; i < valArrLen; i++) {
                valueCounts[valArr[i][0]] = valArr[i][1]
            }

            // re-order the data
            const oldList = this.data.get(fieldName);
            const valueList = new Map<string, string[]>();

            for(const value in valueCounts) {
                valueList.set(value, oldList.get(value))
            }

            this.data.set(fieldName, valueList);
        }
    }

    deleteRecord(recordId): boolean {
        for(const fieldName in this.data) {
            for(const fieldValue in this.data.get(fieldName)) {
                let deleted = false;
                for(const index in this.data.get(fieldName).get(fieldValue)) {
                    if (this.data.get(fieldName).get(fieldValue)[index] == recordId) {
                        delete(this.data.get(fieldName).get(fieldValue)[index]);
                        deleted = true;
                    }
                }
                if (deleted) {
                    if (this.data.get(fieldName).get(fieldValue).length === 0) {
                        this.data.get(fieldName).delete(fieldValue);
                    }
                }
            }
            if (this.data[fieldName].length == 0) {
                delete(this.data[fieldName]);
            }
        }

        return true;
    }

    replaceRecord(recordId: string, recordValues: Map<string, string|string[]>): boolean {
        if(!this.deleteRecord(recordId)) {
            return false;
        }

        return this.addRecord(recordId, recordValues);
    }

}
