import './styles.css';

export default function(selectElement: HTMLSelectElement) {
  selectElement.style.display = 'none';

  const container = selectElement.parentElement;
  let options = [];

  for (let i = 0, l = selectElement.options.length; i < l; i++) {
    options.push(selectElement.options[i]);
  }

  options = options.sort(function (a, b) {
    return a.innerHTML.toLowerCase().charCodeAt(0) - b.innerHTML.toLowerCase().charCodeAt(0);
  });

  for (let i = 0, l = options.length; i < l; i++) {
    const option = options[i];

    // skip empty option values
    if (option.value == '') {
      continue;
    }

    const tag = document.createElement('button');
    tag.type = 'button';
    tag.innerHTML = option.innerHTML;
    tag.classList.add('btn');
    tag.classList.add('btn-xs');
    tag.classList.add('btn-rounded');
    tag.classList.add('btn-outline');
    tag.classList.add('mr-1');
    tag.classList.add('mb-1');

    if (option.selected) {
      tag.classList.add('btn-primary');
    }

    tag.addEventListener('click', () => {
      tag.classList.toggle('btn-primary');
      option.selected = !option.selected;
      selectElement.dispatchEvent(new Event('change'))
    })
    container.append(tag);
  }

  console.log('select tags', selectElement);
}
