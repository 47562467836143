import './styles.css';

/**
 * Validate a min and max value for a range slider against defined constraints (min, max, step).
 */
const constrainRangeSliderValues = (
  /**
   * Tuple containing min and max values.
   */
  values: [ number, number ],
  /**
   * Min allowed value for the sliders.
   */
  min?: number | null,
  /**
   * Max allowed value for the sliders.
   */
  max?: number | null,
  /**
   * Step value for the sliders.
   */
  step = 1,
  /**
   * Whether we're currently interacting with the min range slider or not, so we update the correct values.
   */
  isMin = false
): [ number, number ] => {
  let [ minValue, maxValue ] = values;

  const isFinite = ( n: number | undefined ): n is number =>
    Number.isFinite( n );

  if ( ! isFinite( minValue ) ) {
    minValue = min || 0;
  }

  if ( ! isFinite( maxValue ) ) {
    maxValue = max || step;
  }

  if ( isFinite( min ) && min > minValue ) {
    minValue = min;
  }

  if ( isFinite( max ) && max <= minValue ) {
    minValue = max - step;
  }

  if ( isFinite( min ) && min >= maxValue ) {
    maxValue = min + step;
  }

  if ( isFinite( max ) && max < maxValue ) {
    maxValue = max;
  }

  if ( ! isMin && minValue >= maxValue ) {
    minValue = maxValue - step;
  }

  if ( isMin && maxValue <= minValue ) {
    maxValue = minValue + step;
  }

  return [ minValue, maxValue ];
};

export default function (wrapperElement: HTMLDivElement, minRangeElement: HTMLInputElement, maxRangeElement: HTMLInputElement) {
  const min = +(minRangeElement.min || 0);
  const max = +(minRangeElement.max || 100);
  const minOutput = wrapperElement.querySelector('output[for="' + minRangeElement.id + '"]');
  const maxOutput = wrapperElement.querySelector('output[for="' + maxRangeElement.id + '"]');

  // Works around an IE issue where only one range selector is visible by changing the display order based on the mouse position.
  function findClosestRange(event) {
    const bounds = ( event.target as Element ).getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const minWidth = minRangeElement.offsetWidth;
    const minValue = +minRangeElement.value;
    const maxWidth = maxRangeElement.offsetWidth;
    const maxValue = +maxRangeElement.value;

    const minX = minWidth * ( minValue / max );
    const maxX = maxWidth * ( maxValue / max );

    const minXDiff = Math.abs( x - minX );
    const maxXDiff = Math.abs( x - maxX );

    /**
     * The default z-index in the stylesheet as 20. 20 vs 21 is just for determining which range
     * slider should be at the front and has no meaning beyond
     */
    if ( minXDiff > maxXDiff ) {
      minRangeElement.style.zIndex = '20';
      maxRangeElement.style.zIndex = '21';
    } else {
      minRangeElement.style.zIndex = '21';
      maxRangeElement.style.zIndex = '20';
    }
  }

  // Update handler
  function update(value: [ number, number ] = null) {
    if (value) {
      if (value[0] !== +minRangeElement.value) {
        minRangeElement.value = value[0];
      }

      if (value[1] !== +maxRangeElement.value) {
        maxRangeElement.value = value[1];
      }
    }

    const low = 100 * ((+minRangeElement.value - min) / (max - min)) - 0.5;
    const high = 100 * ((+maxRangeElement.value - min) / (max - min)) + 0.5;

    // update wrapper
    wrapperElement.style.setProperty("--low", low + "%");
    wrapperElement.style.setProperty("--low-offset", (6.5 - (low * 0.15)) + "px");
    wrapperElement.style.setProperty("--high", high + "%");
    wrapperElement.style.setProperty("--high-offset", (6.5 - (high * 0.15)) + "px");

    // update output if present
    if (minOutput) {
      minOutput.innerHTML = minRangeElement.value;
    }
    if (maxOutput) {
      maxOutput.innerHTML = maxRangeElement.value;
    }
  }

  // Init all stuff
  wrapperElement.classList.add("multirange");

  // some ie 11 hacks
  wrapperElement.addEventListener('focus', findClosestRange);
  wrapperElement.addEventListener('mousemove', findClosestRange);

  // add progress element
  const progressElement = document.createElement('div');
  progressElement.classList.add('progress');
  wrapperElement.prepend(progressElement);

  minRangeElement.classList.add("min-range");
  maxRangeElement.classList.add("max-range");

  minRangeElement.addEventListener("input", function (event: InputEvent<HTMLInputElement>) {
    const targetValue = +event.target.value;

    const currentValues: [ number, number ] = [
      targetValue,
      +maxRangeElement.value,
    ];

    const values = constrainRangeSliderValues(
      currentValues,
      min,
      max,
      +minRangeElement.step,
      true
    );

    update(values);
  });

  maxRangeElement.addEventListener("input", function (event: InputEvent<HTMLInputElement>) {
    const targetValue = +event.target.value;

    const currentValues: [ number, number ] = [
      +minRangeElement.value,
      targetValue,
    ];

    const values = constrainRangeSliderValues(
      currentValues,
      min,
      max,
      +maxRangeElement.step,
      false
    );

    update(values);
  });

  update();
}
