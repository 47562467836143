import {on} from "./events";

let googleTls;

on('google.translate', function (e) {
  if (document.documentElement.getAttribute('data-translator') !== null) {
    googleTls.showBanner();
    return;
  }

  const head = document.getElementsByTagName('head')[0];
  const googleTranslate = document.createElement('script');
  googleTranslate.setAttribute('type', 'text/javascript');
  googleTranslate.setAttribute('src', ('https:' == document.location.protocol ? 'https://' : 'http://') + 'translate.google.com/translate_a/element.js?cb=googleTranslateInit');
  head.insertBefore(googleTranslate, head.firstChild);

  document.documentElement.setAttribute('data-translator', 'google');
});

export function googleTranslateInit() {
  googleTls = new google.translate.TranslateElement({
    pageLanguage: document.documentElement.lang,
    layout: google.translate.TranslateElement.InlineLayout.SIMPLE
  });

  // const googleTranslateClicked = storage.cookie.get('google-translate');
  // if(!googleTranslateClicked) {
    googleTls.showBanner();
  // }

  // storage.cookie.set('google-translate', true);
}
