import {Filter} from "./filter";
import {SORT_DIRECTION, SORT_FLAG} from "./sort";

export class SearchQuery {
    records: string[] = [];
    filters: Filter[] = [];
    order: any;
    limit: number;

    addFilter(filter: Filter): this {
        this.filters.push(filter)
        return this;
    }

    addFilters(filters: Filter[]): this {
        this.filters.push(...filters)
        return this;
    }

    getFilters(): Filter[] {
        return this.filters
    }

    setInRecords(records: string[]): this {
        this.records = records;
        return this;
    }

    getInRecords(): string[] {
        return this.records
    }

    sort(filedName: string, direction: SORT_DIRECTION = SORT_DIRECTION.ASC, flags: SORT_FLAG = SORT_FLAG.REGULAR): this {
        this.order = new Order(filedName, direction, flags)
        return this;
    }

    getOrder() {
        return this.order;
    }

}

export class Order {

    private fieldName: string;
    private direction: SORT_DIRECTION = SORT_DIRECTION.ASC;
    private sortFlags: SORT_FLAG = SORT_FLAG.REGULAR;

    /**
     * Aggregation results sort
     * @param int $direction
     * @param int $sortFlags
     *  Sorting type flags:
     *     SORT_REGULAR - compare items normally; the details are described in the comparison operators section
     *     SORT_NUMERIC - compare items numerically
     *     SORT_STRING - compare items as strings
     *     SORT_LOCALE_STRING - compare items as strings, based on the current locale. It uses the locale, which can be changed using setlocale()
     *     SORT_NATURAL - compare items as strings using "natural ordering" like natsort()
     *     SORT_FLAG_CASE - can be combined (bitwise OR) with SORT_STRING or SORT_NATURAL to sort strings case-insensitively
     *
     * @return int[]
     */
    constructor(fieldName: string, direction: SORT_DIRECTION, flags: SORT_FLAG) {
        this.fieldName = fieldName;
        this.direction = direction;
        this.sortFlags = flags;
    }

    getFieldName(): string {
        return this.fieldName;
    }

    getDirection(): SORT_DIRECTION {
        return this.direction;
    }

    getSortFlags(): SORT_FLAG {
        return this.sortFlags
    }
}
