import {on} from '../../events';
import {Storage, ArrayStorage} from "./storage";
import {AggregationResults, AggregationSort, FiltersSort, SORT_DIRECTION, SORT_FLAG} from "./sort";
import {Scanner} from "./scanner";
import {Filter, ValueFilter} from "./filter";
import {SearchQuery} from "./search";

class ArrayIntersection {

  getIntersectMapCount(a: [], b: Map<number|string, mixed>): number {
    let intersectLen = 0;

    for (const key of a) {
      if (b.has(key)) {
        intersectLen++;
      }
    }

    return intersectLen;
  }

  hasIntersectIntMap(a: [], b: []): boolean {
    for (const key of a) {
      if (b[key]) {
        return true;
      }
    }

    return false;
  }

}

// class ArrayResults {
//
// }

class AggregationQuery {
  private filters: Filter[] = [];
  private needCount = false;
  private records = [];
  private aggregationSort = null;

  addFilter(filter: Filter): this {
    this.filters.push(filter)
    return this;
  }

  addOrReplaceFilter(filter: Filter): this {
    this.filters = this.filters.filter(e => e.getFieldName() !== filter.getFieldName())
    this.filters.push(filter);
    return this;
  }

  addFilters(filters: Filter[]): this {
    this.filters.push(...filters)
    return this;
  }

  countItems(count = true): this {
    this.needCount = count;
    return this;
  }

  getCountItems(): boolean {
    return this.needCount
  }

  inRecords(records: number[]): this {
    this.records = records;
    return this;
  }

  getInRecords(): number[] {
    return this.records
  }

  getFilters(): Filter[] {
    return this.filters
  }

  resetFilters(): void {
    this.filters = [];
  }

  sort(direction: SORT_DIRECTION = SORT_DIRECTION.ASC, flags: SORT_FLAG = SORT_FLAG.REGULAR): this {
    this.aggregationSort = new AggregationSort(direction, flags)
    return this;
  }

  getSort(): any {
    return this.aggregationSort
  }
}

const facetedSearch = (storageType: string) => {
  let storage: Storage|null = null;

  if (storageType == 'array') {
    storage = new ArrayStorage;
  } else {
    console.error(`storage type ${storageType} not supported, please use array`)
  }

  const filterSort = new FiltersSort;
  const aggregationSort = new AggregationResults;
  // querySort = new ArrayResults;
  const scanner = new Scanner;
  const intersection = new ArrayIntersection;

  function mapInputArray(data: number[]): Map<number, boolean> {
    const result = new Map;
    for (const i in data) {
      result[data[i]] = true;
    }
    return result;
  }

  function mergeFilters(maps: Map<string, Map<number,boolean>>, skipKey = null): Map<number|string,boolean> {
    let result = [];
    let start = true;

    for(const [key, map] of maps) {
      if (skipKey !== null && skipKey == key) {
        continue
      }

      if (start) {
        result = map;
        start = false;
        continue;
      }

      for(const [k, v] of result) {
        if (!map[k]) {
          delete(result[k]);
        }
      }
    }

    return result;
  }

  function aggregationScan(resultCache: Map<string, Map<number,boolean>>,  filteredRecords: Map<number|string, boolean>,  countRecords: boolean,  input: []): Map<number|string, Map<string, number|boolean>> {
    const result = new Map<number|string, Map<string, number|boolean>>();
    const storageData = storage.getData();

    if (storageData.size == 0) {
      return result;
    }

    const cacheCount = resultCache.size

    for(const [filterName, filterValues] of storageData) {
      let recordIds = filteredRecords;

      // do not apply self filtering
      if (resultCache.has(filterName)) {
        // count of cached filters must be > 1 (1 filter will be skipped by field name)
        if (cacheCount > 1) {
          recordIds = mergeFilters(resultCache, filterName)
        } else {
          recordIds = scanner.findRecordsMap(storage, [], input)
        }
      }

      for (const [filterValue, data] of filterValues) {
        if (countRecords) {
          const intersect = intersection.getIntersectMapCount(data, recordIds);

          if (intersect === 0) {
            continue;
          }

          if (!result.has(filterName)) {
            result.set(filterName, new Map());
          }

          result.get(filterName).set(filterValue, intersect);
        }

        if (intersection.hasIntersectIntMap(data, recordIds)) {
          if (!result.has(filterName)) {
            result.set(filterName, new Map());
          }

          result.get(filterName).set(filterValue, true);
        }
      }
    }
    return result;
  }

  return {
    storage: function () {
      return storage;
    },
    getValuesCount: function () {
      const result = [];
      for (const filterName in storage.data) {
        for (const key in storage.data[filterName]) {
          if (!result[filterName]) {
            result[filterName] = {};
          }
          result[filterName][key] = storage.data[filterName][key].length;
        }
      }
      return result;
    },
    getValues: function () {
      const result = [];
      for (const filterName in storage.data) {
        for (const key in storage.data[filterName]) {
          if (!result[filterName]) {
            result[filterName] = {};
          }
          result[filterName][key] = true;
        }
      }
      return result;
    },
    query: function (query: SearchQuery): string[] {
      const inputRecords = query.getInRecords();
      let filters = query.getFilters();
      const order = query.getOrder();

      let input: Map<number, boolean> = new Map<number, boolean>();

      if (inputRecords.length > 0) {
        input = mapInputArray(inputRecords)
      }

      // Aggregates optimization for value filters.
      // The fewer elements after the first filtering, the fewer data copies and memory allocations in iterations
      if (inputRecords.length === 0 && filters.length > 1) {
        filters = filterSort.byCount(storage, filters);
      }

      const map = scanner.findRecordsMap(storage, filters, input)

      if (order) {
        // TODO
      }

      return Array.from(map.keys());
    },
    aggregate: function (query: AggregationQuery): [any] {
      const records = query.getInRecords();
      let filters = query.getFilters();
      const countValues = query.getCountItems();
      const sort = query.getSort();

      let result: any;

      // Return all values from index if filters and input is not set
      if (filters.length == 0 && records.length == 0) {
        if (countValues) {
          result = this.getValuesCount();
        } else {
          result = this.getValues();
        }

        if (sort) {
          aggregationSort.sort(sort, result);
        }

        return result;
      }

      let input: Map<number, boolean> = new Map<number, boolean>();

      if (records.length > 0) {
        input = mapInputArray(records);
      }

      let filteredRecords = new Map<number,boolean>();
      const resultCache = new Map<string, Map<number,boolean>>();

      if (filters.length > 0) {
        // Aggregates optimization for value filters.
        // The fewer elements after the first filtering, the fewer data copies and memory allocations in iterations
        if (filters.length > 1) {
          filters = filterSort.byCount(storage, filters);
        }
        // index filters by field
        for(const filter of filters) {
          const name = filter.getFieldName();
          resultCache.set(name, scanner.findRecordsMap(storage, [filter], input))
        }
        // merge results
        filteredRecords = mergeFilters(resultCache);
      } else if (input && input.size > 0) {
        filteredRecords = scanner.findRecordsMap(storage, [], input);
      }

      // intersect index values and filtered records
      result = aggregationScan(resultCache, filteredRecords, countValues, input);

      if (sort !== null) {
        result = aggregationSort.sort(sort, result);
      }

      return result;
    }
  }
};

const matrixSwitch = (container, dimensions, combinations, current) => {
  const search = facetedSearch('array')
  const query = new AggregationQuery();

  function getVariantSelector(dimension: string, variant: string) {
      // find variant key by variant value
      for(const key in dimensions[dimension]) {
          if (key == variant) {
              return `[data-dimension="${dimension}"][data-variant="${key}"]`
          }
      }

      throw new Error(`could not find dimension ${dimension} variant ${variant} key in ${JSON.stringify(dimensions)}`);
  }

  const component = {
    init: function () {
      for(const key in combinations) {
        const recordData = combinations[key];
        const recordKey = recordData['identifier'];
        delete(recordData['identifier'])

        search.storage().addRecord(recordKey, recordData)
      }

      search.storage().optimize()

      // setup default matrix switch positions and add them to query filter
      for(const dimension in current) {
        const variant = current[dimension].toString();
        query.addFilter(new ValueFilter(dimension, variant))
      }

      container.querySelectorAll('.product-variations a').forEach((element: HTMLElement) => {
        element.addEventListener('click', () => {
          if (element.classList.contains('disabled')) {
            query.resetFilters();
          }

          component.addFilter(element.dataset['dimension'], element.dataset['variant']);
          component.resetUI();
          component.updateUI(true);
        })
      })

      component.updateUI(false);
    },
    addFilter: function (dimension, variant) {
      query.addOrReplaceFilter(new ValueFilter(dimension, variant))
    },
    resetUI: function() {
      container.querySelectorAll('.product-variations a').forEach((element: HTMLElement) => {
        element.classList.add('disabled');
        element.classList.remove('active');
      });
    },
    updateUI: function(open: boolean) {
      if (open) {
        const searchQuery = (new SearchQuery()).addFilters(query.getFilters());
        const records = search.query(searchQuery);

        if (records.length === 1) {
          const identifier = records[0];
          const link = container.querySelector(`[data-variant="${identifier}"]`) as HTMLLinkElement;
          if (link) {
            window.location = link.href;
          }
        } else {
          console.debug('multiple records found', records);
        }
      }

      const availableCombinations = search.aggregate(query.countItems().sort())

      for(const [dimension, variants] of availableCombinations) {
        for (const [variant, count] of variants) {
          const dimensionVariantElement = container.querySelector(getVariantSelector(dimension, variant));

          if (!dimensionVariantElement) {
            console.error('could not find matrix dimension variant element on page', dimension, variant);
            continue;
          }

          dimensionVariantElement.classList.remove('disabled');
        }
      }

      const filters = query.getFilters();

      for (const i in filters) {
        const filter = filters[i]
        const dimension = filter.getFieldName();
        const values = filter.getValue();
        for (const j in values) {
          const variant = values[j];
          const dimensionVariantElement = container.querySelector(getVariantSelector(dimension, variant));

          if (!dimensionVariantElement) {
            console.error('could not find matrix dimension variant element on page', dimension, variant);
            continue;
          }

          dimensionVariantElement.classList.remove('disabled');
          dimensionVariantElement.classList.add('active');
        }
      }
    }
  }

  return component;
};

on('render.product.matrix.switch', function (params) {
  const data = JSON.parse(params.data);
  matrixSwitch(params.element, data.dimensions, data.combinations, data.current).init()
})
