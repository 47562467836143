import {on} from "./events";

async function submit(form:HTMLFormElement) {
  return await fetch('/', {
    method: form.method,
    body: new FormData(form),
    headers: {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
  })
    .then((response: Response) => response.json())
    .then((json) => {
      if (!json.success) {
        throw new Error(json.error);
      }

      return json;
    })
    .catch((error) => console.log("error", error));
}

on('wishlist.toggle', async function (data) {
  console.log("wishlist toggle ADD start");

  data.event.preventDefault();

  const button = data.element as HTMLElement;
  const form = document.getElementById('wishlist-product-' + data.identifier) as HTMLFormElement;

  button.classList.add('load-more-overlay');
  button.classList.add('loading');

  await submit(form);

  if (!button.classList.contains('added')) {
    button.classList.remove('w-icon-heart');
    button.classList.add('added');
    button.classList.add('w-icon-heart-full');
  } else {
    button.classList.remove('added');
    button.classList.remove('w-icon-heart-full');
    button.classList.add('w-icon-heart');
  }

  button.classList.remove('load-more-overlay');
  button.classList.remove('loading');

  console.log("wishlist toggle", data, button, form);
});

on('wishlist.close', function() {
  const container = document.querySelector('.page-wrapper > .compare-popup');

  if (!container) {
    return;
  }

  container.classList.remove('show');
});
