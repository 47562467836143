import {intval} from '../../helper';

export interface Filter {
    /**
     * Get field name
     * @return string
     */
    getFieldName(): string;

    /**
     * Set filter value
     */
    setValue(value: any): void;

    /**
     * Get filter value
     * @return any
     */
    getValue(): any

    /**
     * Filter faceted data
     * @param facetedData
     * @param inputIdKeys
     */
    filterInput(facetedData: Map<number|string, number[]>, inputIdKeys: Map<number, boolean>): any;
}

class AbstractFilter implements Filter {
    fieldName: string;
    value: any;

    constructor(fieldName: string, value: any = null) {
        this.fieldName = fieldName
        this.setValue(value)
    }

    getFieldName(): string {
        return this.fieldName;
    }

    setValue(value: any): void {
        this.value = value;
    }

    getValue(): any {
        return this.value;
    }

    filterInput(facetedData: Map<number|string, number[]>, inputIdKeys: Map<number, boolean>): any {
        console.error('you should override abstract class filterInput function')
        return null
    }
}

export class ValueFilter extends AbstractFilter {
    setValue(value: any): void {
        if (!Array.isArray(value)) {
            if (typeof value == 'boolean') {
                value = intval(value, 10)
            }
            if (parseFloat(value * 1) != parseInt(value * 1)) {
                value = `${value}`
            }
            this.value = [value];
            return;
        }

        for(const [key, item] of value) {
            if (typeof item == 'boolean') {
                value[key] = intval(item, 10)
            }
            if (parseFloat(item * 1) != parseInt(item * 1)) {
                value[key] = `${item}`
            }
        }

        this.value = value;
    }

    filterInput(facetedData: Map<number|string, number[]>, inputIdKeys: Map<number, boolean>): Map<number,boolean> {
        if (inputIdKeys.size == 0) {
            return this.filterData(facetedData);
        }

        const result = new Map<number,boolean>()

        // collect list for different values of one property
        for(const item of this.value) {
            if (!facetedData.has(`${item}`)) {
                continue;
            }

            const values = facetedData.get(item);

            for(const recId of values) {
                if (inputIdKeys.has(recId)) {
                    result.set(recId, true);
                }
            }
        }

        return result;
    }

    filterData(facetedData: Map<number|string, number[]>): Map<number, boolean> {
        let result = new Map<number, boolean>();

        for (const item of this.value) {
            if (!facetedData.has(`${item}`)) {
                continue
            }

            const data = facetedData.get(`${item}`);

            if (result.size === 0) {
                result = this.fillArray(data, true)
                continue
            }

            for(const recId of data) {
                result.set(recId, true);
            }
        }

        return result;
    }

    private fillArray(keys, value): Map<any, boolean> {
        const retObj = new Map()
        for (const key in keys) {
            retObj.set(keys[key], value);
        }
        return retObj
    }
}
