import {emit, on} from "./events";

on('filter.listener', function (e) {
  const formElement = e.element;

  if (!(formElement instanceof HTMLFormElement)) {
    throw new Error('filter listener must be initialized on form element');
  }

  formElement.addEventListener('submit', function (event) {
    e.preventDefault();
    emit('form.submit', { element: formElement, event: event });
  });

  formElement.querySelectorAll('input,select,button').forEach((element) => {
    if (element instanceof HTMLInputElement) {
      element.addEventListener('change', (event) => {
        console.log('changed');
        emit('form.submit', { element: formElement, event: event });
      });
    } else if (element instanceof HTMLSelectElement) {
      element.addEventListener('change', (event) => {
        console.log('changed');
        emit('form.submit', { element: formElement, event: event });
      });
    } else if (element instanceof HTMLButtonElement) {
      element.addEventListener('click', (event) => {
        console.log('changed', event.target, event.target.type);
        emit('form.submit', { element: formElement, event: event, reset: ((event.target as HTMLButtonElement).type === 'reset') });
      });
    } else {
      throw new Error('this field type is not supported');
    }
  })
});

on('page.filter', function (e) {
    window.location = Wolmart.addUrlParam(location.href, e.element.name, e.element.value);
});
