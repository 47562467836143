import './styles.css';

/**
 * Validate a min and max value for a range slider against defined constraints (min, max, step).
 */
const constrainRangeSliderValues = (
  /**
   * The slider value.
   */
  value: number,
  /**
   * Min allowed value for the sliders.
   */
  min?: number | null,
  /**
   * Max allowed value for the sliders.
   */
  max?: number | null,
  /**
   * Step value for the sliders.
   */
  step = 1,
  /**
   * Whether we're currently interacting with the min range slider or not, so we update the correct values.
   */
  isMin = false
): number => {
  const isFinite = ( n: number | undefined ): n is number =>
    Number.isFinite( n );

  if ( ! isFinite( value ) ) {
    value = min || 0;
  }

  if ( isFinite( min ) && min > value ) {
    value = min;
  }

  if ( isFinite( max ) && max <= value ) {
    value = max - step;
  }

  return value;
};

export default function (wrapperElement: HTMLDivElement, rangeElement: HTMLInputElement) {
  const min = +(rangeElement.min || 0);
  const max = +(rangeElement.max || 100);
  const bubble = wrapperElement.querySelector('output[for="' + rangeElement.id + '"]');

  // Update handler
  function update(value: number = null) {
    if (value) {
      if (value !== +rangeElement.value) {
        rangeElement.value = value[0];
      }
    }

    const low = 100 * ((+rangeElement.value - min) / (max - min));

    wrapperElement.style.setProperty('--bubble', `${low}%`);
    wrapperElement.style.setProperty('--bubble-offset', `${6.5 - (low * 0.15)}px`);

    bubble.innerHTML = String(rangeElement.value);
  }

  // Init all stuff
  wrapperElement.classList.add('range');

  // add progress element
  const progressElement = document.createElement('div');
  progressElement.classList.add('progress');
  wrapperElement.prepend(progressElement);

  rangeElement.addEventListener('input', function (event: InputEvent<HTMLInputElement>) {
    const targetValue = +event.target.value;

    const value = constrainRangeSliderValues(
      targetValue,
      min,
      max,
      +rangeElement.step,
      true
    );

    update(value);
  });

  update();
}
