import {on} from './events';
import {open} from './minipopup';
import {showErrors} from "./errors";
import {addLoadingIndicator, removeLoadingIndicator} from "./helper";

on('cart.add', async function (data) {
  data.event.preventDefault();

  let form: HTMLFormElement;

  if (data.event.target instanceof HTMLFormElement) {
    form = data.even.target;
  } else {
    form = document.getElementById(data.form) as HTMLFormElement;
  }

  console.log("cart.add", form.action, form.method, data, new FormData(form));

  const result = await fetch(form.action, {
    method: form.method,
    body: new FormData(form),
    headers: {
      'Accept': 'application/json'
    },
  })
    .then((response: Response) => response.json())
    .then((json) => json)
    .then((json) => {
      if (!json.success) {
        throw new Error(json.error);
      }

      return json;
    })
    .catch((error) => console.log("error", error));

  console.log("result", result);
});

on('cart.changed', function (data) {
  const updateButton = document.querySelector('.page-content').querySelector('.cart-action').querySelector('.btn-update');

  if (updateButton) {
    updateButton.classList.remove('disabled');
    updateButton.removeAttribute('disabled');
  }
});

on('model.update.cart', function (data) {
  const model = data.model;
  console.log('model.update.cart', data, model, model.errors);

  if (model.errors) {
    showErrors(model.errors);
    return;
  }

  document.querySelectorAll('[data-model-cart]').forEach((element) => {
    const property = element.getAttribute('data-model-cart');
    const value = model.cart[property];

    if (value !== undefined) {
      element.innerHTML = value;
    }
  });

  document.querySelectorAll('[data-cart-fragment]').forEach((element) => {
    if (!(element instanceof HTMLDivElement)) {
        return;
    }

    const url = element.getAttribute('data-cart-fragment');

    if (!url) {
        return;
    }

    addLoadingIndicator(element);
    fetch(url, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    }).then((response: Response) => response.text()).then((response) => {
      removeLoadingIndicator(element);
      element.innerHTML = response
    })
  });

  if (model.cart['_minicart'] !== undefined && model.cart['_minicart'].length != 0) {
    document.querySelectorAll('[data-partial="mini-cart"]').forEach((element) => {
      element.innerHTML = model.cart['_minicart'];
    });
  }

  if (model.cart['_minipopup'] !== undefined && model.cart['_minipopup'].length != 0) {
    open({
      template: model.cart['_minipopup']
    });
  }
});

on('cart.shipping.method.update', async function (data) {
    if (!data.target.closest('.js-address-fieldset')) {
        return;
    }

    const form = data.form as HTMLFormElement;
    const action = (typeof form.action === 'string' ? form.action : '/');

    const result = await fetch(action, {
        method: form.method,
        body: new FormData(form),
            headers: {
                'Accept': 'application/json'
            },
        })
        .then((response: Response) => response.json());

    if (result.errors) {
        return;
    }

    document.querySelectorAll('[data-checkout-fragment]').forEach((element) => {
        if (!(element instanceof HTMLDivElement)) {
            return;
        }

        const url = element.getAttribute('data-checkout-fragment');

        if (!url) {
            return;
        }

        addLoadingIndicator(element);
        fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then((response: Response) => response.text()).then((response) => {
            removeLoadingIndicator(element);
            element.innerHTML = response
        })
    });
})
