import {emit, on} from "./events";

on('model.update.address', function (data) {
    const model = data.model;

    if (model.redirect !== undefined) {
        window.location = model.redirect;
        return;
    }

    console.log('model.update.address', data);

    if (model.errors) {
        for(const field in model.errors) {
            for(const error in model.errors[field]) {
                console.log('error', field, error)
            }
        }
    }
});

on('address.form.init', function (data) {
  const addressToggles = data.element.querySelectorAll(data["address-toggle"]);

  addressToggles.forEach((element) => {
    element.addEventListener('change', function () {
      const target = document.querySelector(element.getAttribute('data-target'));

      if (target) {
        if (element.value) {
          target.classList.add('d-none');
          target.querySelectorAll('[data-required]').forEach((e) => e.required = false);
        } else {
          target.classList.remove('d-none');
          target.querySelectorAll('[data-required]').forEach((e) => e.required = true);
        }
      }
    });
  });

  const sameAsInput = data.element.querySelector('input[name=shippingAddressSameAsBilling]');
  const shippingToggle = data.element.querySelector(data["shipping-toggle"]);
  const shippingTarget = document.querySelector(data["shipping-target"]);
  let defaultDisplay = window.getComputedStyle(shippingTarget).getPropertyValue('display');

  if (defaultDisplay == 'none') {
    defaultDisplay = 'inherit';
  }

  shippingToggle.addEventListener('change', (event) => {
    if (event.currentTarget.checked) {
      sameAsInput.value = 0;
      shippingTarget.style.display = defaultDisplay;

      if (addressToggles.length === 0) {
        shippingTarget.querySelectorAll('[data-required]').forEach((e) => e.required = true);
      }
    } else {
      sameAsInput.value = 1;
      shippingTarget.style.display = 'none';
      shippingTarget.querySelectorAll('[data-required]').forEach((e) => e.required = false);
    }
  });

  if (shippingToggle.checked && addressToggles.length === 0) {
    shippingTarget.querySelectorAll('[data-required]').forEach((e) => e.required = true);
  }

  const checkoutForm = data.element.closest('form') as HTMLFormElement;

  if (checkoutForm) {
      let checkoutFormSubmitTimeoutID = 0;

      checkoutForm.addEventListener('input', function (e) {
          let shippingMethodFieldValid = true;
          const shippingMethodFields: HTMLInputElement[] = Array.from(checkoutForm.querySelectorAll('[data-shipping-method]'));

          for (const field of shippingMethodFields) {
              if (!field.checkValidity()) {
                  shippingMethodFieldValid = false;
              }
          }

          if (!shippingMethodFieldValid) {
              return;
          }

          if (checkoutFormSubmitTimeoutID) {
              clearTimeout(checkoutFormSubmitTimeoutID);
          }

          checkoutFormSubmitTimeoutID = setTimeout(() => {
              emit('cart.shipping.method.update', {
                  form: checkoutForm,
                  target: e.target,
              });
          }, 800);
      });
  }
});

on('address.country.update', function (data) {
  const element = data.element as HTMLSelectElement;
  let selectedOption = null;
  let countryPhoneCode = null;

  if (element.selectedIndex >= 0) {
    selectedOption = element.options[element.selectedIndex];
    countryPhoneCode = selectedOption.getAttribute('data-phone-region-code')
  }

  if (selectedOption === null || countryPhoneCode === null) {
    return;
  }

  document.querySelector(data['phone-region-field']).value = selectedOption.value;
  document.querySelector(data['phone-region-view']).innerHTML = '+' + countryPhoneCode;

  // did user change shipping country?
  let isShippingCountry = element.name.indexOf('shipping') >= 0

  if (!isShippingCountry) {
    // client wants to use billing as shipping ?
    const sameAsInput = document.querySelector('input[name=shippingAddressSameAsBilling]') as HTMLFormElement;

    if (sameAsInput.value == 1) {
        isShippingCountry = true;
    }
  }
});
