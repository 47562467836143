import { defineCustomElement } from 'vue'
import component from './component.vue'
customElements.define('address-autocomplete', defineCustomElement(component))


/*
Usage Example:
ISSUE: FormData(form) will not include this field value
<div class="form-group {{ 'js-address-field' }}">
    <label>
        {{- 'Address 1'|t('site') -}}
        <abbr class="required" title="required">*</abbr>
    </label>
    <address-autocomplete
          id="addressLine1"
          url="{{ siteUrl('actions/wolmart-theme/address/street-autocomplete') }}"
          name="addressLine1"
          value="{{ address.addressLine1 ?? '' }}"
          placeholder="{{ 'House number and street name'|t('site') }}"
          {{ (optional == false ? 'required') }}
          data-required="true"
          data-shipping-method="true"
          input-class="form-control form-control-md"
          country-field-selector="#{{ 'countryCode'|namespaceInputId }}"></address-autocomplete>
    {{ _self.displayErrors(address, 'addressLine1') }}
    </div>
<script>
document.querySelector('#{{ 'addressLine1'|namespaceInputId }}').addEventListener('valueChanged', event => {
    event.target.value = event.detail[0];
});
document.querySelector('#{{ 'addressLine1'|namespaceInputId }}').addEventListener('fillAddress', event => {
    var detail = event.detail[0];
    var address = detail.street || '';
    if (detail.houseNumber) {
        address += ' ' + detail.houseNumber;
    }
    console.log('selected event', event)
    console.log('selected address', address)
    document.querySelector('#{{ 'addressLine1'|namespaceInputId }}').value = address;
    document.querySelector('#{{ 'locality'|namespaceInputId }}').value = detail.city || '';
    document.querySelector('#{{ 'postalCode'|namespaceInputId }}').value = detail.postalCode || '';
    document.querySelector('#{{ 'administrativeArea'|namespaceInputId }}').value = detail.county || '';
});
</script>
 */
